const ErrorIcon = ({color="#E83100", ...params}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4357_3927" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect x="0.5" y="0.5" width="23" height="23" fill="#D9D9D9" stroke="#1E1E1E"/>
        </mask>
        <g mask="url(#mask0_4357_3927)">
        <path d="M12.0014 17.3033L12.002 10" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.002 7.28284L12.002 7" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z" stroke={color}/>
        </g>
        </svg>
    )
}

export default ErrorIcon;