import { ForkLeft, KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Collapse, Paper } from "@mui/material";
import "../styles/faqs.css";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const FAQsData = [
    {
        question: "What is a resume parser and how does it work?",
        answer:
          "A resume parser is a tool or software that automatically extracts key details like contact info, experience, and skills from resumes using AI and ML, organizing the data for quick review and saving you time.",
      },
      {
        question: "What formats does the resume parser support?",
        answer:
          "Our resume parser supports PDF, DOC, DOCX, and TXT formats, ensuring compatibility with most resume submissions.",
      },
      {
        question: "How accurate is the resume parsing technology?",
        answer:
          "Our parser achieves over 95% accuracy in data extraction, minimizing errors and ensuring reliable candidate information.",
      },
      {
        question: "Can the resume parser integrate with my existing systems?",
        answer:
          "Yes, our resume parser seamlessly integrates with popular Applicant Tracking Systems (ATS) and HR software, fitting smoothly into your existing workflow.",
      },
      {
        question: "How does the resume parser improve the hiring process?",
        answer:
          "By automating resume screening, our parser speeds up your recruitment process, enhances accuracy, and helps you quickly identify top candidates.",
      },
      {
        question: "Is my data secure with your resume parser?",
        answer:
          "Absolutely. We prioritize data security with robust measures to protect your sensitive information and ensure confidentiality.",
      },
      {
        question: "How can I get started with the resume parser?",
        answer:
          "Just sign up for a free trial on our website, and we’ll guide you through the quick and easy setup process.",
      },  
    // {
    //     question : "How long can I enjoy free trial service?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Do I need to add any Credit Card to get free trial service?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "What is the package cancellation process ?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Is there any hidden charge beside the main price?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // },
    // {
    //     question : "Is there any extra fee to pay for outside US citizen?",
    //     answer : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget."
    // }
]

const FAQsItem = ({faq})=>{
    const [open, setOpen] = useState(false);
    
    return (
        <div className={`faqs-item`} onClick={()=>setOpen(prev=>!prev)}>
            <div className="faqs-question">
                {faq.question}
                <h3 className="faqs-button">{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</h3>
            </div>
            <Collapse in={open} >
                <div className="faqs-bar"></div>
                <div className="faqs-answer">{faq.answer}</div>
            </Collapse>
        </div>
    )
}

const FAQs = ()=>{
    return (
        <>
            <div className="home-container" style={{height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"}}>
                <Paper
                    elevation={0}
                    sx={{
                        width: isMobile ? "100%" : "93%",
                        height: isMobile ? "100%" : "80%",
                        marginTop:isMobile ? null : "0.5rem",
                        padding:"0.5rem 1rem",
                        borderRadius:isMobile ? "20px" : "32px",
                        overflow:"auto",
                        background:"#EA723C1A",
                        scrollbarGutter:"stable"
                    }}
                >
                    <div
                        style={{
                            marginLeft:"1rem",
                            fontSize:"1.6rem",
                            fontWeight:"800",
                            color:"#EA723C",
                            marginTop:"1rem",
                        }}
                    >Frequently asked questions</div>
                    <p
                        style={{
                            fontSize:"0.9rem",
                            margin:"0.5rem 1rem 1.5rem 1rem",
                            fontStyle:"italic"
                        }}
                    >Questions you might ask about our products and services</p>
                    <div
                        style={{
                            width:"calc(100% - 2rem)",
                            padding:"0rem 1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                        }}
                    >
                        {
                            FAQsData.map(item=>(
                                <FAQsItem faq={item} />
                            ))
                        }
                    </div>
                </Paper>
            </div>
        </>
    )
}

export default FAQs;