import { useEffect, useState } from "react";
import "../styles/socket-status.css";
import { toast } from "react-toastify";

const SocketStatus = ({connected}) => {
    const [toastId, setToastId] = useState(null);
    useEffect(()=>{
        if(connected === null) return;

        if(connected){
            // toast.success("Socket Connected", {closeOnClick:true});
            if(toastId === null) return;
            toast.dismiss(toastId);
        }else{
            setToastId(
                toast.error("Network Disconnected", {autoClose:false})
            );
        }
    }, [connected])
    return (
        null
        // connected === null ?
        // <div className="socket-status status-pending">
        //     Socket Connecting...
        // </div> :
        // connected ? null :
        // <div className="socket-status status-error">
        //     Socket Disconnected
        // </div>
    )
}

export default SocketStatus;