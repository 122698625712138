import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import socket from "./socket.js";
import Login from "./components/Login.jsx";
import Dashboard from "./components/Dashboard.jsx";
import SocketStatus from "./components/SocketStatus.jsx";
import Authenticator from "./components/Authenticator.jsx";
import { UploadQueueProvider } from "./contexts/UploadQueueContext.jsx";

function App() {
  const [isConnected, setIsConnected] = useState(null);
  useEffect(()=>{
    socket.connect();
    socket.on('connect', ()=>{
      console.log("socket connected")
      setIsConnected(true);
    })
    socket.on('disconnect', ()=>{
      console.log("socket disconnected")
      setIsConnected(false);
    })

    const timer = setTimeout(()=>{
      if(!socket.connected){
        setIsConnected(false);
      }
    }, 2000);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      clearTimeout(timer);
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <SocketStatus connected={isConnected} />
      <Router>
        <Routes>
          <Route exact path="/*" element={
            <UploadQueueProvider>
              <Authenticator Dashboard={Dashboard} />
            </UploadQueueProvider>
          }/>
          <Route path="/auth/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
