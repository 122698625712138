const LoginPageImage = ({...props}) => {
    return (
      <svg
        //   width="944"
        //   height="535"
        width="750"
        height="450"
        {...props}
        viewBox="0 0 944 535"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M870.804 533.044C870.804 533.044 982.558 398.456 927.961 259.881C892.37 169.547 758.816 120.077 683.417 169.547C651.315 190.61 548.334 170.244 493.337 82.9996C386.467 -86.6058 13.5198 16.4199 81.444 276.791C114.308 402.808 -18.5473 407.36 49.1436 533.343L870.804 533.044Z"
          fill="#EA723C"
          fillOpacity="0.2"
        />
        <path
          d="M788.06 311.509C788.06 311.509 799.324 298.353 788.757 290.013C788.757 290.013 781.048 285.33 774.734 285.728C774.734 285.728 783.673 300.679 780.782 302.174C777.891 303.668 772.042 284.798 772.042 284.798C772.042 284.798 754.464 283.104 748.814 285.828C748.814 285.828 763.834 298.121 760.677 298.852C757.521 299.583 741.271 287.522 741.271 287.522C741.271 287.522 724.921 290.313 725.786 300.28C726.649 310.247 732.299 313.337 734.758 313.071C737.25 312.806 751.739 307.257 752.038 309.317C752.337 311.376 743.63 317.988 737.516 318.288C737.516 318.288 749.047 331.012 756.722 327.324C764.399 323.669 765.03 317.822 769.816 315.662C774.601 313.47 778.688 313.47 776.695 315.696C774.734 317.921 766.061 320.082 763.336 324.933C760.611 329.749 758.152 333.038 766.094 331.842C774.035 330.646 786.929 324.832 787.494 318.187C788.06 311.509 788.06 311.509 788.06 311.509Z"
          fill="#74B570"
        />
        <path
          d="M827.471 294.966C827.471 294.966 809.526 284.932 817.901 271.31C817.901 271.31 824.58 262.938 831.758 260.779C831.758 260.779 827.936 281.112 831.758 281.577C835.579 282.042 834.383 258.62 834.383 258.62C834.383 258.62 853.258 249.55 860.668 250.246C860.668 250.246 848.938 270.081 852.793 269.616C856.614 269.151 869.774 249.052 869.774 249.052C869.774 249.052 889.147 245.463 892.237 256.924C895.361 268.387 890.31 274.135 887.452 274.866C884.594 275.596 866.185 275.331 866.65 277.722C867.115 280.115 879.543 283.935 886.489 281.776C886.489 281.776 878.846 300.648 868.81 299.716C858.774 298.753 855.651 292.54 849.436 292.075C843.222 291.61 838.67 293.271 841.793 294.933C844.917 296.594 855.418 295.498 860.436 299.75C865.454 304.003 869.508 306.66 860.203 308.555C850.865 310.481 834.15 309.284 830.794 302.109C827.471 294.966 827.471 294.966 827.471 294.966Z"
          fill="#74B570"
        />
        <path
          d="M883.43 260.545C883.43 260.545 833.218 276.791 827.502 294.965C827.502 294.965 827.735 303.105 822.717 312.407V315.996C822.717 315.996 827.968 303.802 830.859 302.14C830.826 302.14 819.594 286.359 883.43 260.545Z"
          fill="#90DD8B"
        />
        <path
          d="M822.68 312.406C822.68 312.406 814.937 324.168 819.357 338.454L821.351 337.922C821.351 337.922 819.424 322.173 823.478 314.233C827.532 306.326 822.68 312.406 822.68 312.406Z"
          fill="#90DD8B"
        />
        <path
          d="M798.958 281.144C798.958 281.144 822.584 267.921 811.551 249.947C811.551 249.947 802.712 238.918 793.275 236.093C793.275 236.093 798.325 262.872 793.275 263.503C788.224 264.134 789.818 233.27 789.818 233.27C789.818 233.27 764.928 221.309 755.159 222.239C755.159 222.239 770.612 248.386 765.56 247.755C760.509 247.124 743.196 220.644 743.196 220.644C743.196 220.644 717.675 215.926 713.587 231.044C709.5 246.159 716.114 253.734 719.902 254.666C723.69 255.596 747.947 255.297 747.316 258.452C746.685 261.608 730.302 266.659 721.164 263.802C721.164 263.802 731.233 288.686 744.493 287.423C757.718 286.161 761.839 277.955 770.013 277.357C778.221 276.726 784.202 278.918 780.082 281.144C775.994 283.336 762.105 281.875 755.491 287.49C748.879 293.105 743.528 296.593 755.79 299.085C768.086 301.61 790.15 300.015 794.537 290.579C798.958 281.144 798.958 281.144 798.958 281.144Z"
          fill="#74B570"
        />
        <path
          d="M725.216 235.762C725.216 235.762 791.411 257.191 798.955 281.145C798.955 281.145 798.656 291.842 805.27 304.135V308.854C805.27 308.854 798.324 292.774 794.569 290.58C794.536 290.58 809.356 269.782 725.216 235.762Z"
          fill="#90DD8B"
        />
        <path
          d="M805.237 304.134C805.237 304.134 815.44 319.616 809.624 338.488L806.999 337.789C806.999 337.789 809.558 317.025 804.208 306.559C798.891 296.094 805.237 304.134 805.237 304.134Z"
          fill="#90DD8B"
        />
        <path
          d="M733.796 300.514C733.796 300.514 778.226 298.786 788.062 311.511C788.062 311.511 790.222 318.023 796.901 323.936L797.932 326.761C797.932 326.761 790.222 318.654 787.464 318.156C787.497 318.156 791.818 302.408 733.796 300.514Z"
          fill="#90DD8B"
        />
        <path
          d="M796.931 323.935C796.931 323.935 806.469 330.978 807.133 343.604L805.405 343.77C805.405 343.77 802.347 330.746 796.864 325.629C791.381 320.513 796.931 323.935 796.931 323.935Z"
          fill="#90DD8B"
        />
        <path
          d="M799.754 379.386C774.2 379.386 753.464 358.655 753.464 333.105H846.045C846.045 358.655 825.309 379.386 799.754 379.386Z"
          fill="#1E1E1E"
        />
        <path
          d="M752.801 381.744H846.678C846.678 378.588 844.119 376.029 840.962 376.029H758.518C755.36 376.029 752.801 378.588 752.801 381.744Z"
          fill="black"
        />
        <path d="M752.4 333.105H847.572V334.535H752.4V333.105Z" fill="black" />
        <path
          d="M560.829 532.08H560.596C558.735 532.047 557.273 530.519 557.473 528.759L569.967 404.137L580.867 405.101L562.956 530.386C562.79 531.383 561.893 532.08 560.829 532.08Z"
          fill="black"
        />
        <path
          d="M681.021 532.08H681.254C683.115 532.047 684.577 530.519 684.378 528.759L671.883 404.137L660.983 405.101L678.894 530.386C679.061 531.383 679.957 532.08 681.021 532.08Z"
          fill="black"
        />
        <path
          d="M472.8 532.08H472.569C470.708 532.047 469.245 530.519 469.444 528.759L481.94 404.137L492.84 405.101L474.927 530.386C474.728 531.383 473.831 532.08 472.8 532.08Z"
          fill="#8CB4F4"
        />
        <path
          d="M677.302 385.997L699.466 276.625C702.324 261.809 690.029 248.287 674.111 248.652L631.376 249.615C616.622 249.947 603.829 259.814 599.741 273.968L567.342 385.997H677.302Z"
          fill="black"
        />
        <path
          d="M663.907 385.997L686.072 276.625C688.93 261.809 676.635 248.286 660.717 248.652L617.983 249.615C603.228 249.947 590.434 259.814 586.347 273.968L553.947 385.997H663.907Z"
          fill="#434343"
        />
        <path
          d="M671.118 405.964H502.074C498.684 405.964 495.893 403.172 495.893 399.784V392.176C495.893 388.786 498.684 385.996 502.074 385.996H677.299V399.784C677.299 403.172 674.508 405.964 671.118 405.964Z"
          fill="black"
        />
        <path
          d="M591.796 405.964H484.029C481.304 405.964 479.044 403.737 479.044 400.98V390.98C479.044 388.255 481.27 385.996 484.029 385.996H591.796C594.521 385.996 596.779 388.223 596.779 390.98V400.98C596.779 403.705 594.521 405.964 591.796 405.964Z"
          fill="#434343"
        />
        <path
          d="M727.746 384.568V388.289C727.746 389.585 728.178 390.648 728.676 390.648H864.855C865.386 390.648 865.785 389.585 865.785 388.289V384.568C865.785 383.272 865.353 382.209 864.855 382.209H728.643C728.143 382.209 727.746 383.272 727.746 384.568Z"
          fill="#F9D0B0"
        />
        <path
          d="M727.745 384.568V388.289C727.745 389.585 728.144 390.648 728.609 390.648H836.708C837.174 390.648 837.572 389.585 837.572 388.289V384.568C837.572 383.272 837.174 382.209 836.708 382.209H728.576C728.11 382.209 727.745 383.272 727.745 384.568Z"
          fill="#EA723C"
        />
        <path
          d="M762.203 390.682H858.904V531.581H762.203V390.682Z"
          fill="#F9D0B0"
        />
        <path
          d="M735.088 390.682H831.789V531.581H735.088V390.682Z"
          fill="#EA723C"
        />
        <path
          d="M740.306 523.807H826.605V394.668H740.306V523.807ZM825.675 522.877H741.236V395.598H825.675V522.877Z"
          fill="#F9D0B0"
        />
        <path
          d="M740.769 437.395H826.139V438.325H740.769V437.395Z"
          fill="#F9D0B0"
        />
        <path
          d="M740.769 480.949H826.139V481.879H740.769V480.949Z"
          fill="#F9D0B0"
        />
        <path
          d="M774.7 403.007H790.55C791.315 403.007 791.947 402.376 791.947 401.612C791.947 400.848 791.315 400.217 790.55 400.217H774.7C773.936 400.217 773.305 400.848 773.305 401.612C773.305 402.376 773.936 403.007 774.7 403.007Z"
          fill="#F9D0B0"
        />
        <path
          d="M774.7 445.6H790.55C791.315 445.6 791.947 444.969 791.947 444.204C791.947 443.44 791.315 442.809 790.55 442.809H774.7C773.936 442.809 773.305 443.44 773.305 444.204C773.305 444.969 773.936 445.6 774.7 445.6Z"
          fill="#F9D0B0"
        />
        <path
          d="M774.7 489.986H790.55C791.315 489.986 791.947 489.355 791.947 488.59C791.947 487.826 791.315 487.195 790.55 487.195H774.7C773.936 487.195 773.305 487.826 773.305 488.59C773.305 489.355 773.936 489.986 774.7 489.986Z"
          fill="#F9D0B0"
        />
        <path
          d="M530.061 363.106C539.199 379.021 550.697 385.166 550.697 385.166H598.549C598.549 385.166 523.082 350.98 530.061 363.106Z"
          fill="#233862"
        />
        <path
          d="M471.043 521.217C471.575 526.234 464.564 527.529 456.488 528.028C448.38 528.526 450.872 530.154 439.773 532.28C428.707 534.407 424.222 531.416 423.39 529.921C422.66 528.593 427.811 526.465 433.294 524.04C433.991 523.742 434.689 523.443 435.353 523.11C441.502 520.32 444.924 514.738 449.71 509.522C454.229 504.604 458.416 488.923 458.416 488.923L473.469 485.535C473.469 485.535 469.381 499.622 467.986 505.635C467.156 509.156 468.484 513.11 469.647 516.1C470.213 517.761 470.844 519.423 471.043 521.217Z"
          fill="#FFB27D"
        />
        <path
          d="M456.455 527.995C448.346 528.493 450.839 530.121 439.74 532.247C428.674 534.374 424.188 531.383 423.358 529.888C422.626 528.559 427.777 526.432 433.26 524.007L433.36 524.107C433.36 524.107 441.202 526.665 450.174 524.14C457.486 522.081 464.563 517.595 469.615 516.133C470.246 517.761 470.877 519.422 471.043 521.184C471.541 526.233 464.563 527.53 456.455 527.995Z"
          fill="#0F2549"
        />
        <path
          d="M493.835 346.827C501.944 341.145 555.512 331.013 582.861 328.055L613.1 301.809C613.1 301.809 638.82 339.916 639.02 369.053C639.119 382.642 632.839 384.004 632.839 384.004C632.839 384.004 535.939 384.735 531.686 385.166C524.74 385.897 515.568 403.206 513.275 418.954C510.982 434.736 470.574 498.857 470.574 498.857C470.574 498.857 462.133 501.547 458.246 501.316C454.058 501.082 452.13 499.654 452.13 499.654C452.13 499.654 475.559 359.65 493.835 346.827Z"
          fill="black"
        />
        <path
          d="M496.094 521.217C496.626 526.234 489.613 527.529 481.538 528.028C473.431 528.526 475.923 530.154 464.824 532.28C453.725 534.407 449.272 531.416 448.441 529.921C447.71 528.593 452.86 526.465 458.343 524.04C459.042 523.742 459.74 523.443 460.404 523.11C466.553 520.32 469.974 514.738 474.76 509.522C479.279 504.604 483.466 488.923 483.466 488.923L498.52 485.535C498.52 485.535 494.432 499.622 493.037 505.635C492.207 509.156 493.535 513.11 494.698 516.1C495.264 517.761 495.928 519.423 496.094 521.217Z"
          fill="#FFB27D"
        />
        <path
          d="M481.543 527.995C473.435 528.493 475.927 530.121 464.828 532.247C453.729 534.374 449.277 531.383 448.446 529.888C447.714 528.559 452.865 526.432 458.348 524.007L458.448 524.107C458.448 524.107 466.291 526.665 475.263 524.14C482.574 522.081 489.652 517.595 494.703 516.133C495.334 517.761 495.965 519.422 496.131 521.184C496.629 526.233 489.618 527.53 481.543 527.995Z"
          fill="#233862"
        />
        <path
          d="M519.127 346.827C526.172 341.876 547.34 338.056 570.702 335.198C577.148 334.401 582.465 329.716 584.06 323.403L638.392 301.809C638.392 301.809 664.112 339.916 664.311 369.053C664.411 382.642 658.131 384.004 658.131 384.004C658.131 384.004 561.23 384.735 556.977 385.166C550.031 385.897 540.86 403.206 538.567 418.954C536.274 434.736 495.866 498.857 495.866 498.857C495.866 498.857 487.425 501.547 483.537 501.316C479.35 501.082 477.423 499.654 477.423 499.654C477.423 499.654 500.85 359.65 519.127 346.827Z"
          fill="#121212"
        />
        <path
          d="M591.868 143.532C599.345 139.612 610.71 139.081 612.538 138.848C634.636 135.891 647.928 167.985 648.692 177.686C650.122 195.628 659.625 198.351 672.984 209.58C699.768 232.107 691.893 258.253 677.271 267.954C668.498 273.802 653.013 267.954 653.013 267.954C652.78 268.12 610.677 244.432 589.908 233.004C558.771 228.551 572.828 153.533 591.868 143.532Z"
          fill="black"
        />
        <path
          d="M596.454 221.94C596.354 221.973 606.256 226.492 613.435 224.132C620.18 221.907 626.095 211.774 625.995 211.774C623.071 211.342 620.114 208.484 619.283 191.408L617.987 191.74L596.22 197.222C596.22 197.222 597.085 204.099 597.45 210.611C597.749 216.092 597.683 221.309 596.454 221.94Z"
          fill="#FFB27D"
        />
        <path
          d="M596.22 197.222C596.22 197.222 596.22 198.651 597.017 205.064C609.313 201.973 614.763 197.886 618.584 193.203L596.22 197.222Z"
          fill="#ED975D"
        />
        <path
          d="M591.605 198.086C591.605 198.086 620.382 202.771 623.008 184.796C625.633 166.856 631.149 155.36 612.872 151.606C594.629 147.852 590.043 154.198 587.683 159.979C585.358 165.76 584.161 196.392 591.605 198.086Z"
          fill="#FFB27D"
        />
        <path
          d="M591.569 150.876C591.569 150.876 604.629 171.508 616.293 176.391C627.956 181.275 633.672 178.784 633.672 178.784C633.672 178.784 628.355 170.744 626.993 159.945C626.595 156.756 624.501 154.031 621.543 152.836C613.169 149.48 596.985 144.198 591.569 150.876Z"
          fill="black"
        />
        <path
          d="M603.035 152.869C603.035 152.869 595.393 156.789 592.235 160.477C589.178 164.031 585.689 173.667 585.921 177.985C585.921 177.985 582.133 157.255 590.009 150.145C598.349 142.569 603.035 152.869 603.035 152.869Z"
          fill="black"
        />
        <path
          d="M573.453 265.828C574.318 324.301 573.687 312.076 580.1 317.623C581.628 318.953 585.982 319.75 591.664 320.116C609.608 321.312 641.111 318.355 642.308 315.032C646.428 303.571 643.537 300.015 646.96 282.441C647.325 280.579 647.758 278.553 648.289 276.36C651.18 264.3 653.972 260.214 643.77 240.844C634.498 223.303 623.632 209.05 622.768 209.581C598.343 224.067 597.513 211.94 597.513 211.94C597.513 211.94 573.021 237.29 573.453 265.828Z"
          fill="#EA723C"
        />
        <path
          d="M597.516 211.939C597.516 211.939 572.526 216.192 569.567 226.989C566.611 237.821 549.265 301.411 549.265 301.411C549.265 301.411 562.025 303.703 567.94 297.789C573.855 291.875 588.476 243.037 588.476 243.037L597.516 211.939Z"
          fill="#EA723C"
        />
        <path
          d="M591.666 320.114C609.612 321.311 641.114 318.353 642.31 315.031C646.431 303.569 643.54 300.013 646.963 282.439L638.323 258.086C638.323 258.086 639.751 285.197 631.742 292.34C623.768 299.483 599.011 306.093 595.122 312.074C593.262 314.965 592 317.822 591.666 320.114Z"
          fill="#E7916A"
        />
        <path
          d="M612.205 308.721C612.205 308.721 594.526 304.767 591.668 306.361C588.81 307.922 581.832 313.637 580.968 315.63C580.104 317.624 587.315 314.135 589.674 313.504C591.202 313.106 595.191 314.501 597.185 315.165C605.526 317.957 610.642 316.528 610.642 316.528L612.205 308.721Z"
          fill="#FFB27D"
        />
        <path
          d="M622.774 209.58C622.774 209.58 647.797 211.042 656.038 222.505C667.602 238.651 690.565 307.423 677.406 316.594C662.584 326.926 609.948 319.284 609.948 319.284C609.948 319.284 608.186 311.111 610.645 307.124C613.071 303.171 652.682 296.759 654.509 294.932C656.337 293.105 631.681 259.316 631.681 253.834C631.646 248.385 622.774 209.58 622.774 209.58Z"
          fill="#EA723C"
        />
        <path
          d="M590.805 311.277C590.805 311.277 587.082 316.061 585.887 316.626C584.691 317.191 583.892 317.058 583.892 317.058L586.385 312.572L590.805 311.277Z"
          fill="#FFB27D"
        />
        <path
          d="M591.668 306.359L580.901 311.576C580.901 311.576 581.366 312.34 582.696 312.273C584.024 312.207 587.382 310.978 587.382 310.978L591.668 306.359Z"
          fill="#FFB27D"
        />
        <path
          d="M551.954 318.752H602C602 316.559 600.205 314.766 598.013 314.766H551.954V318.752Z"
          fill="#C3C3C3"
        />
        <path
          d="M552.085 264.268H474.724L493.266 318.754H571.16L552.085 264.268Z"
          fill="#C3C3C3"
        />
        <path
          d="M473.567 264.268L492.143 318.754H570.035L550.962 264.268H473.567Z"
          fill="#848484"
        />
        <path
          d="M515.299 291.01C515.764 293.104 517.858 294.765 519.952 294.765C522.045 294.765 523.341 293.071 522.843 291.01C522.377 288.917 520.284 287.256 518.19 287.256C516.097 287.256 514.801 288.917 515.299 291.01Z"
          fill="white"
        />
        <path
          d="M619.314 331.013H280.196C278.766 331.013 277.604 329.85 277.604 328.422V321.312C277.604 319.883 278.766 318.721 280.196 318.721H619.314C620.743 318.721 621.906 319.883 621.906 321.312V328.422C621.906 329.85 620.743 331.013 619.314 331.013Z"
          fill="#585858"
        />
        <path
          d="M271.888 532.978L271.755 532.945C270.027 532.746 268.798 531.185 268.997 529.489L299.204 324.967L310.27 325.299L274.713 530.918C274.48 532.248 273.25 533.145 271.888 532.978Z"
          fill="#585858"
        />
        <path
          d="M630.678 532.978L630.811 532.945C632.538 532.746 633.768 531.185 633.569 529.489L603.362 324.967L592.296 325.299L627.853 530.918C628.053 532.248 629.315 533.145 630.678 532.978Z"
          fill="#585858"
        />
        <path
          d="M560.695 532.978L560.828 532.945C562.556 532.746 563.785 531.185 563.586 529.489L533.38 324.967L522.314 325.299L557.872 530.918C558.103 532.248 559.333 533.145 560.695 532.978Z"
          fill="#585858"
        />
        <path
          d="M2.48975 534.671H941.686C942.384 534.671 942.982 534.106 942.982 533.375V532.312C942.982 531.614 942.418 531.016 941.686 531.016H2.48975C1.79132 531.016 1.19312 531.581 1.19312 532.312V533.375C1.22742 534.106 1.79132 534.671 2.48975 534.671Z"
          fill="black"
        />
        <path
          d="M382.412 532.81H322.431C319.54 529.288 320.603 516.299 321.036 512.079C321.134 511.215 321.202 510.717 321.202 510.717C321.202 510.717 341.672 494.338 344.496 506.897C344.596 507.294 344.696 507.661 344.795 508.058C348.351 519.787 364.235 525.103 373.407 526.597C379.388 527.561 381.615 530.651 382.412 532.81Z"
          fill="#233862"
        />
        <path
          d="M218.784 338.023C220.944 338.023 321.501 356.894 338.149 377.493C346.456 387.792 347.686 506.532 347.686 506.532C347.686 506.532 337.418 519.29 315.22 511.881C315.22 511.881 298.936 430.617 300.133 405.999C300.465 399.188 231.446 405.001 218.784 401.746C206.124 398.489 175.286 370.981 182.064 357.592C188.877 344.169 218.784 338.023 218.784 338.023Z"
          fill="#233862"
        />
        <path
          d="M337.719 308.918C337.719 308.918 357.989 309.184 362.808 311.243C367.627 313.304 377.33 318.021 374.737 318.752C372.145 319.484 359.717 318.453 357.026 318.851C354.334 319.25 351.044 318.752 349.382 318.752C347.755 318.752 340.078 318.752 340.078 318.752L337.719 308.918Z"
          fill="#FFB27D"
        />
        <path
          d="M361.444 260.246C361.444 260.246 368.356 255.297 375.069 254.333C378.89 253.802 382.612 253.103 384.872 252.306C391.052 250.114 403.481 238.984 402.517 237.654C399.526 233.469 385.038 242.339 382.512 241.376C379.986 240.413 387.364 235.163 384.805 233.336C383.476 232.373 380.32 237.189 377.761 237.689C374.304 238.353 371.579 243.236 370.549 243.502C368.522 244 359.616 250.612 359.616 250.612L361.444 260.246Z"
          fill="#FFB27D"
        />
        <path
          d="M228.82 202.57C228.82 202.57 244.272 207.355 259.459 249.25C274.679 291.144 280.461 293.404 285.977 295.032C291.493 296.659 340.043 306.327 340.043 306.327V318.753H265.241C265.241 318.753 237.195 292.074 228.787 274.134C220.413 256.159 228.82 202.57 228.82 202.57Z"
          fill="#E0E0E0"
        />
        <path
          d="M408.932 532.81H348.951C346.06 529.288 347.123 516.299 347.555 512.079C347.654 511.215 347.721 510.717 347.721 510.717C347.721 510.717 368.191 494.338 371.015 506.897C371.115 507.294 371.216 507.661 371.314 508.058C374.871 519.787 390.754 525.103 399.927 526.597C405.908 527.561 408.102 530.651 408.932 532.81Z"
          fill="#1B3051"
        />
        <path
          d="M248.431 338.023C250.59 338.023 351.146 356.894 367.795 377.493C376.103 387.792 377.332 506.532 377.332 506.532C377.332 506.532 367.063 519.29 344.866 511.881C344.866 511.881 328.583 430.617 329.779 405.999C330.111 399.188 261.091 405.001 248.431 401.746C235.802 398.489 204.931 370.981 211.711 357.592C218.523 344.169 248.431 338.023 248.431 338.023Z"
          fill="#2B478B"
        />
        <path
          d="M242.147 215.827C234.902 215.229 227.327 213.301 220.779 211.175C212.571 208.517 205.992 204.63 210.245 197.155C214.167 190.244 220.514 174.828 220.082 162.07L235.334 174.363L245.703 182.702C245.703 182.702 245.669 187.62 244.54 196.258C244.341 197.819 244.074 199.513 243.808 201.341C243.576 202.803 244.24 204.264 245.503 205.062C245.57 205.095 245.669 205.161 245.736 205.195C250.854 208.418 248.195 216.325 242.147 215.827Z"
          fill="#FFB27D"
        />
        <path
          d="M245.667 180.677C245.667 180.677 246.2 182.471 245.302 189.049C236.064 187.554 227.424 180.179 227.424 180.179C232.11 178.882 233.837 173.8 237.326 174.298L245.667 180.677Z"
          fill="#ED975D"
        />
        <path
          d="M254.21 182.172C254.21 182.172 218.687 188.086 215.363 165.893C212.04 143.733 205.195 129.546 227.726 124.861C250.256 120.143 255.939 127.951 258.863 135.094C261.787 142.204 263.348 180.078 254.21 182.172Z"
          fill="#FFB27D"
        />
        <path
          d="M264.081 125.26C265.609 130.609 261.522 137.088 254.676 139.247C244.508 142.437 246.768 154.895 242.182 154.729C237.597 154.563 235.769 147.553 231.682 153.168C228.192 157.952 234.573 163.599 234.14 166.225C233.376 170.709 226.365 179.913 220.017 179.448C213.67 178.983 201.807 148.848 204.167 139.546C206.526 130.21 216.03 120.41 225.002 119.047C233.974 117.685 246.469 121.572 249.36 121.572C252.251 121.572 263.284 122.37 264.081 125.26Z"
          fill="#2B478B"
        />
        <path
          d="M181.633 233.335C186.75 212.67 200.939 191.008 210.543 191.008C226.892 191.041 250.387 195.361 259.891 218.75C266.87 235.993 276.207 335.131 265.673 342.174C241.581 358.287 185.753 391.212 175.851 357.656C166.745 326.925 172.693 269.182 181.633 233.335Z"
          fill="white"
        />
        <path
          d="M221.312 212.77C225.964 200.645 241.018 196.458 251.286 204.433C256.836 208.751 263.614 214.897 271.823 223.635C302.329 256.127 308.542 255.928 314.258 255.23C319.974 254.566 368.391 244.3 368.391 244.3C368.391 244.3 370.883 246.127 372.346 249.084C374.306 253.038 373.309 255.729 373.309 255.729C375.204 254.499 356.162 267.623 346.659 272.873C334.13 279.782 315.256 286.261 307.745 288.72C305.618 289.418 303.325 289.384 301.198 288.62C290.032 284.6 252.582 269.948 222.077 244.533C214.035 237.822 216.992 224.034 221.312 212.77Z"
          fill="white"
        />
        <path
          d="M208.884 191.208L212.574 182.869C212.574 182.869 245.704 185.561 251.287 204.432C251.287 204.432 225.599 191.64 208.884 191.208Z"
          fill="white"
        />
        <path
          d="M128.464 532.08H128.232C126.371 532.047 124.909 530.519 125.108 528.759L137.603 404.137L148.502 405.101L130.591 530.386C130.392 531.383 129.494 532.08 128.464 532.08Z"
          fill="black"
        />
        <path
          d="M192.7 532.08H192.467C190.606 532.047 189.144 530.519 189.344 528.759L201.838 404.137L212.738 405.101L194.827 530.386C194.628 531.383 193.731 532.08 192.7 532.08Z"
          fill="black"
        />
        <path
          d="M313.296 532.08H313.528C315.388 532.047 316.851 530.519 316.652 528.759L304.156 404.137L293.258 405.101L311.169 530.386C311.335 531.383 312.232 532.08 313.296 532.08Z"
          fill="black"
        />
        <path
          d="M131.989 386.495L120.159 282.406C118.598 267.424 123.616 252.739 145.282 251.575L183.365 249.25C205.927 248.32 216.13 267.09 218.987 281.576L241.584 395.998L131.989 386.495Z"
          fill="black"
        />
        <path
          d="M138.336 405.964H307.381C310.77 405.964 313.561 403.172 313.561 399.784V392.176C313.561 388.786 310.77 385.996 307.381 385.996H132.189V399.784C132.155 403.172 134.947 405.964 138.336 405.964Z"
          fill="black"
        />
        <path
          d="M136.97 405.964H236.761C239.387 405.964 241.547 403.804 241.547 401.179V390.814C241.547 388.189 239.387 386.029 236.761 386.029H132.151V401.179C132.151 403.804 134.312 405.964 136.97 405.964Z"
          fill="black"
        />
      </svg>
    );
  };
  
  export default LoginPageImage;
  