import React, { useEffect, useState } from 'react';
import "../styles/custom-grid-pagination.css";
import { CustomSelect } from './CustomMUIInputs';
import LeftArrowIcon from '../icons/LeftArrowIcon';
import RightArrowIcon from '../icons/RightArrowIcon';
import { Collapse } from '@mui/material';
import useResponsiveView from '../hooks/useResponsiveView';

const CustomGridPagination = ({ gridAPI }) => {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [firstPageNumber, setFirstPageNumber] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [startRow, setStartRow] = useState(0);
    const [endRow, setEndRow] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const isMobile = useResponsiveView();

    useEffect(() => {
        if (gridAPI) {
            const updatePagination = () => {
                setIsLoading(true);
                gridAPI.setGridOption('paginationPageSize', pageSize);
                const currentPage = gridAPI.paginationGetCurrentPage();
                const totalRows = gridAPI.getDisplayedRowCount();
                const startRow = Math.min(currentPage * pageSize + 1, totalRows);
                const endRow = Math.min(startRow + pageSize - 1, totalRows);
                const tmpTotalPages = Math.ceil(totalRows / pageSize)
                setTotalPages(tmpTotalPages)
                setCurrentPage(currentPage);
                setFirstPageNumber(((currentPage + 2) > (tmpTotalPages - 1)) ? (tmpTotalPages - 2) : (currentPage))
                setTotalRows(totalRows);
                setStartRow(startRow);
                setEndRow(endRow);
                setIsLoading(false);
            };

            updatePagination();

            gridAPI.addEventListener('firstDataRendered', updatePagination);
            gridAPI.addEventListener('paginationChanged', updatePagination);

            return () => {
                if(!gridAPI.isDestroyed()){
                    gridAPI.removeEventListener('firstDataRendered', updatePagination);
                    gridAPI.removeEventListener('paginationChanged', updatePagination);
                }
            };
        }
    }, [gridAPI, pageSize]);

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    }

    const handlePageChange = (newPage) => {
        if (gridAPI) {
            gridAPI.paginationGoToPage(newPage);
        }
    };

    return (
        <div className="custom-pagination-panel">
            <div className="left-side">
                {
                    isMobile ? '' :
                    <div className="current-page">
                        Showing <span>{startRow?.toString().padStart(totalRows?.toString().length, '0')} - {endRow?.toString().padStart(totalRows?.toString().length, '0')}</span> of {totalRows} Entries
                    </div>
                }
                <div className="page-size">
                    <p>Page Size :</p>
                    <CustomSelect
                        options={[2, 20, 50, 100]}
                        value={pageSize}
                        onChange={(event, value)=>handlePageSizeChange(value)}
                        label={""}
                        style = {{
                            height:isMobile ? "1.8rem" : "2rem",
                            width:isMobile ? "4rem" : "5rem",
                            fontSize : isMobile ? "0.75rem" : "0.875rem",
                            margin:"0.5rem",
                            borderRadius : "6px"
                        }}
                        sx={{
                            '.MuiSelect-select': {
                            padding: "2px 10px",
                            color : "#6D6D6D"
                            }
                        }}
                    />
                </div>
            </div>
            <div className="page-controller">
                <button
                    className='text-button'
                    onClick={() => handlePageChange(0)}
                    disabled={currentPage === 0}
                >
                    First
                </button>

                <button
                    className='square-button'
                    onClick={()=>handlePageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                >
                    <LeftArrowIcon color={currentPage === 0 ? '#6D6D6D' : 'black'} />
                </button>
                
                <button className={`square-button page-number ${(firstPageNumber + (totalPages === 1)) === currentPage ? 'selected' : null}`}
                    onClick={()=>handlePageChange(firstPageNumber)}
                >{
                    Math.max(firstPageNumber + 1  + (totalPages === 1), 1)
                }</button>
                <Collapse in={totalPages > 1} mountOnEnter unmountOnExit orientation='horizontal'>
                    <button className={`square-button page-number ${(firstPageNumber + 1 + (totalPages === 1)) === currentPage ? 'selected' : null}`}
                        onClick={()=>handlePageChange(firstPageNumber + 1)}
                    >{
                        firstPageNumber + 2 + (totalPages === 1)
                    }</button>
                </Collapse> 
                
                <button
                    className='square-button'
                    onClick={()=>handlePageChange(currentPage + 1)}
                    disabled={currentPage >= totalPages - 1}
                >
                    <RightArrowIcon color={currentPage >= totalPages - 1 ? '#6D6D6D' : 'black'} />
                </button>

                <button
                    className='text-button'
                    onClick={() => handlePageChange(totalPages - 1)}
                    disabled={currentPage >= totalPages - 1}
                >
                    Last
                </button>
            </div>
        </div>
    );
};

export default CustomGridPagination;
