const HamburgerButtonIcon = ({color="#D9D9D9"}) => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" width="16" height="2" fill={color}/>
            <rect y="8" width="24" height="2" fill={color}/>
            <rect x="14" y="16" width="10" height="2" fill={color}/>
        </svg>

    )
}

export default HamburgerButtonIcon;