import { AgGridReact } from "ag-grid-react";
import "../styles/rank-and-analyze.css";

import { useEffect, useRef, useState } from "react";
import { IconButton, FormControlLabel, Radio, Autocomplete, TextField, RadioGroup, Dialog, DialogTitle, DialogContent, DialogContentText, TableContainer, TableRow, TableCell, TableHead, Table, TableBody, Divider, Tooltip, Fade, Button, Paper } from "@mui/material";
import RankAndAnalyzeIcon from "../icons/RankAndAnalyzeIcon";
import socket from "../socket";
import FormCloseIcon from "../icons/FormCloseIcon";
import CrossIcon from "../icons/CrossIcon";
import CheckIcon from "../icons/CheckIcon";
import YesIcon from "../icons/YesIcon";
import NotDetected from "./NotDetected";
import { AutorenewOutlined } from "@mui/icons-material";
import CrossCircleIcon from "../icons/CrossCircleIcon";
import { CustomAutoComplete, CustomTextField } from "./CustomMUIInputs";
import useResponsiveView from "../hooks/useResponsiveView";
import CustomGridPagination from "./CustomGridPagination";

const scoreRenderer = (params)=>{
    return (
        <div
            style={{
                fontSize:"1rem",
            }}
        >
            {(params.value * 100).toFixed(2)}%
        </div>
    )
}

const CustomTabelCell = ({sx={}, ...params})=>{
    const isMobile = useResponsiveView();
    return (
        <TableCell
            align="center"
            sx={{
                fontFamily:"Montserrat",
                fontSize:isMobile ? "0.8rem" : "1rem",
                padding:isMobile ? "1rem" : undefined,
                ...sx
            }}
            {...params}
        >
            {params.children}
        </TableCell>
    )
}

const CustomHeadRow = ({column1, column2})=>{
    return (
        <div
                style={{
                width:"95%",
                borderRadius:"12px",
                fontFamily:"Montserrat",
                marginBottom:"1rem",
                height:"2rem",
                display:"flex",
                alignItems:"center",
                padding:"0.5rem",
                fontWeight:"600",
                background:"#EA723C33"
            }}
        >
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column1}</div>
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column2}</div>
        </div>
    )
}

const CustomRow = ({column1, column2, borderFlag=true})=>{
    return (
        <div
                style={{
                width:"90%",
                fontFamily:"Montserrat",
                height:"1.5rem",
                display:"flex",
                alignItems:"center",
                padding:"0.75rem",
                borderBottom:borderFlag ? "1px solid #1E1E1E5A" : "none"
            }}
        >
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column1}</div>
            <div
                style={{
                    width:"50%",
                    height:"inherit",
                    textAlign:"center",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}
            >{column2}</div>
        </div>
    )
}

const AnalyzeRenderer = (params)=>{
    const isMobile = useResponsiveView();
    const [isOpen, setIsOpen] = useState(false);
    const [requiredExperience, setRequiredExperience] = useState(null);
    const [candidateExperience, setCandidateExperience] = useState(null);
    const [skillsFound, setSkillsFound] = useState(null);
    const [error, setError] = useState(false);
    const handleClose = ()=>{
        setIsOpen(false);   
    }
    useEffect(()=>{
        if(!isOpen){return}
        let resume = null;
        let jobDescription = null;
        let documentMap = {};
        
        for(let document of params.documents){
            documentMap[document._id] = document;
        }

        if(params.resume){
            resume = params.resume;
            jobDescription = documentMap[params.data._id];
        }
        else if(params.jobDescription){
            resume = documentMap[params.data._id];
            jobDescription = params.jobDescription;
        }

        if(!(resume && jobDescription)){
            setError(true);
            return;
        }
        if(jobDescription.required_experience_in_years && !['none', 'n/a', 'null'].includes(String(jobDescription.required_experience_in_years).toLowerCase())){
            setRequiredExperience(
                jobDescription.required_experience_in_years
            )
        }
        if(resume.work_experience && !['none', 'n/a', 'null'].includes(String(resume.work_experience).toLowerCase())){
            setCandidateExperience(
                resume.work_experience
            )
        }
        if(jobDescription.required_skills && !['none', 'n/a', 'null'].includes(String(jobDescription.required_skills).toLowerCase())){
            const required_skills = jobDescription.required_skills.split(",").map(x=>x.trim());
            if (!resume.skills || ['none', 'n/a', 'null'].includes(String(resume.skills).toLowerCase())){
                resume.skills = "none";
            }
            
            setSkillsFound(
                required_skills.map((item)=>{
                    return [item, String(resume.skills).toLowerCase().includes(String(item).toLowerCase())]
                })    
            )
        }

    }, [isOpen, params.documents, params.jobDescription, params.resume, params.data])
    return (
        error ? null :
        <>
            <Dialog fullWidth open={isOpen} onClose={handleClose} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white"},
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black",
                        position:"relative"
                    }}
                >
                    <div>Analyze</div>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleClose}>
                        <CrossCircleIcon color="black" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        height:"50svh",
                        padding:isMobile ? 0 : undefined
                    }}
                >
                    <CustomHeadRow key={1} column1={"Required Experience"} column2={"Candidate Experience"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        <CustomRow 
                            column1={requiredExperience ? requiredExperience + ' Year(s)' : <NotDetected />} 
                            column2={candidateExperience ? candidateExperience + ' Year(s)' : <NotDetected />}
                            borderFlag={false}
                        />
                    </div>
                    
                    <CustomHeadRow key={2} column1={"Required Skills"} column2={"Found in Resume"}/>
                    <div
                        style={{
                            width:"calc(100% - 1rem)",
                            borderRadius:"16px",
                            border:"1px solid #1E1E1E8B",
                            marginBottom:"1rem",
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center"
                        }}
                    >
                        {
                            !skillsFound ? <div align="center" style={{fontSize:"1rem", padding:"1rem", fontFamily:"Montserrat"}}><NotDetected text={"No Skills detected in Job description"} /></div> :
                            skillsFound.map(([skill, found], index)=>(
                                <CustomRow 
                                    key={index}
                                    column1={skill}
                                    column2={found ? <YesIcon color="#00B350" /> : <CrossIcon color="#FF5E62" />}
                                    borderFlag={index !== (skillsFound.length - 1)}
                                />
                            ))
                        }
                    </div>
                </DialogContent>
            </Dialog>

            <IconButton onClick={()=>setIsOpen(true)}>
                <RankAndAnalyzeIcon />
            </IconButton>
        </>
    )
}

const FilenameRenderer = (params) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <Tooltip title={params.value} open={isOpen} onClick={()=>setIsOpen(true)} onClose={()=>setIsOpen(false)}
                style={{
                    textOverflow:"ellipsis"
                }}
            >
                <div>
                    {params.value}
                </div>
            </Tooltip>
        </div>
    )
}

const RankAndAnalyze = ({documents, updateFlag, updateDocuments}) => {
    const [rowData, setRowData] = useState([]);
    const [filter, setFilter] = useState('job-descriptions');
    const [selectedDocument, setSelectedDocuments] = useState("");
    const gridAPIRef = useRef(null);
    const isMobile = useResponsiveView();
    const handleChangeDocument = (event, newVal) => {
        if(!newVal){
            setSelectedDocuments("");
            setRowData([]);
            return;
        }
        setSelectedDocuments(newVal);
        socket.emit('request-similarity-table', newVal);
    }

    const columnDefs = [
        { headerName: 'Document', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:3,
            minWidth:250,
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",
            },
            cellClass:"name-cell",
            headerClass:"not-centered-header-cell",
            cellRenderer:FilenameRenderer
        },
        { headerName: 'Score', field:'score', sort:"desc", sortable:"true",  cellClass:"centered-cell", cellRenderer:scoreRenderer },
        { headerName: 'Analyze', colId:"analyze", cellClass:"centered-cell", cellRenderer:AnalyzeRenderer, 
            cellRendererParams:{
                resume : filter === 'resumes' ? selectedDocument : undefined,
                jobDescription : filter !== 'resumes' ? selectedDocument : undefined,
                documents : documents
            }
        }
    ]

    useEffect(()=>{
        socket.on("update-similarity-table", (data)=>{
            if(!documents){
                console.log("No Documents");
                return;
            }
            setRowData(data.map(item=>{
                const otherDocument = documents.find(innerItem=>innerItem._id === item._id);
                if(!otherDocument){
                    return otherDocument
                }
                return {
                    _id : item._id,
                    score : item.score,
                    filename : otherDocument.filename,
                }
            }).filter(item=>!!item))
            // setRowData(data);
        })

        return ()=>{
            socket.off("update-similarity-table");
        }
    }, [documents])

    const handleUpdateDocuments = ()=>{
        updateDocuments();
        setSelectedDocuments("");
        setRowData([]);
    }

    return (
        <div className={isMobile ? "home-container" : "rank-and-analyze-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:isMobile ? "100%" : "93%",
                    marginTop:isMobile ? null : "0.5rem",
                    padding:"0.5rem 1rem",
                    height:"100%",
                    borderRadius:isMobile ? "20px" : "32px",
                    display:"flex",
                    flexDirection:"column"
                }}
            >
                <div className="rank-and-analyze-header">
                    <RadioGroup
                        row
                        style={{
                            margin:"1rem 0.5rem 0.5rem 1rem"
                        }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="job-descriptions"
                        name="radio-buttons-group"
                        id="radio-buttons-group"
                        value={filter}
                        onChange={(event, newVal)=>{
                            setFilter(newVal);
                            setSelectedDocuments(null);
                            setRowData([]);
                        }}
                    >
                        <FormControlLabel value="job-descriptions"
                        sx={{
                            background:filter === 'job-descriptions' ? "#EA723C" : undefined,
                            borderRadius:"22px",
                            color:filter === 'job-descriptions' ? "white" : undefined,
                            padding:"0.05rem 0.75rem 0 0",
                        }}
                        control={<Radio 
                            sx={{
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}  
                        />} label="Job Descriptions" />
                        <FormControlLabel value="resumes"
                        sx={{
                            background:filter === 'resumes' ? '#EA723C' : undefined,
                            borderRadius:"22px",
                            color:filter === 'resumes' ? "white" : undefined,
                            padding:"0.05rem 0.75rem 0 0",
                        }}
                        control={<Radio 
                        sx={{
                            '&.Mui-checked': {
                                color: "white",
                            },
                        }}
                        />} label="Resumes" />
                        <Fade in={updateFlag} mountOnEnter unmountOnExit>
                            {
                                isMobile ? 
                                <IconButton
                                    style={{
                                        position:"absolute",
                                        right:"calc(2% + 1rem)"
                                    }}
                                    onClick={handleUpdateDocuments}
                                >
                                    <AutorenewOutlined style={{
                                        color:"#EA723C"
                                    }} />
                                </IconButton>
                                :
                                <Button
                                    variant="contained"
                                    style={{
                                        padding:"0.8rem",
                                        borderRadius:"0.5rem",
                                        font:"inherit",
                                        background:"#EA723C",
                                        margin:"auto 0 auto auto",
                                        height:"2.3rem",
                                        textTransform:"none"
                                    }}
                                    startIcon={<AutorenewOutlined />}
                                    onClick={handleUpdateDocuments}
                                >
                                    Load new documents
                                </Button>
                            }
                        </Fade>
                    </RadioGroup>

                    
                    <CustomAutoComplete
                        disablePortal
                        id="combo-box-demo"
                        options={documents?.filter(x=>x.document_type === filter) || []}
                        getOptionKey={(document)=>document._id}
                        getOptionLabel={(document)=>document ? document.filename : ''}
                        sx={{ width: "100%", margin:"0.5rem 0.5rem"}}
                        defaultValue={null}
                        value={selectedDocument || null}
                        renderInput={(params) => <TextField {...params} label="Select Document" />}
                        onChange={handleChangeDocument}
                    />

                </div>

                <div className={"ag-theme-quartz" + (isMobile ? " mobile" : "")} style={{ width: 'calc(1rem + 100%)', height:"100%", marginLeft:"-0.5rem" }} id="rankingTable">
                    <AgGridReact 
                        pagination={true} 
                        defaultColDef={{wrapHeaderText:true, minWidth:150, flex:1, resizable: false, sortable:false, cellStyle:{textAlign:"center"}}}
                        rowData={rowData} 
                        getRowId={(params)=>params.data._id}
                        columnDefs={columnDefs}
                        rowHeight={"60px"}
                        suppressCellFocus
                        onGridReady={(params)=>gridAPIRef.current = params.api}
                        suppressPaginationPanel
                        paginationPageSize={100}
                    />
                </div>
                <CustomGridPagination gridAPI={gridAPIRef.current} />
            </Paper>
        </div>
    )
}

export default RankAndAnalyze;