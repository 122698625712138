import "../styles/home.css";
import { Paper } from "@mui/material";
import CheckIcon from "../icons/CheckIcon";
import useResponsiveView from "../hooks/useResponsiveView";

const features = [
    'Resume Parsing',
    'Scalability',
    'Keyword Matching',
    'Job Description & Resume Scan',
    'Structured Data Output',
    'Multiple User Roles'
]

const howItWorks = [
    "The parsed resume content is compared against a predefined list of keywords and phrases extracted from the job description provided by the employer",
    "This matching process helps evaluate the suitability of candidates based on their skills and experience in relation to the job requirements",
    "The application supports multiple user roles, such as recruiters, hiring managers, and administrators",
    "Each user role has specific permissions and access levels, allowing for controlled access to candidate data and system functionalities",
    "Recruiters can upload and parse resumes, view candidate profiles, and communicate with applicants"
]

const HowItWorks = () => {
    const isMobile = useResponsiveView();
    return (
        <>
            <div className="home-container">
                <Paper
                    elevation={0}
                    style={{
                        width:isMobile ? "100%" : "93%",
                        marginTop:isMobile ? null : "0.5rem",
                        padding: "0.5rem 1rem",
                        borderRadius:"32px",
                        flexGrow:1,
                        overflow:isMobile ? "hidden" : "auto",
                        background:"#EA723C1A"
                    }}
                >
                    <div
                        style={{
                            marginLeft:"1rem",
                            fontSize:"1.5rem",
                            fontWeight:"800",
                            color:"#EA723C",
                            marginTop:"1rem",
                        }}
                    >Our Process</div>
                    <div className="home-howitworks">
                        {
                            howItWorks.map((item, index)=>(
                                <div key={index} className="home-hiw-item">
                                    {
                                        isMobile ?
                                        <p style={{width:"20px", height:"18px", margin:0}}>
                                            <CheckIcon color="#EA723C" /> 
                                        </p> :
                                        <strong style={{whiteSpace:"nowrap", color:"#1E1E1E", fontWeight:700}}>Step {index+1} :</strong> 
                                    }
                                    <div style={{marginLeft:"0.5rem", color:"#1E1E1E", fontWeight:400}}>{item}</div>
                                </div>
                            ))
                        }
                    </div>
                </Paper>
                
                <Paper
                    elevation={0}
                    style={{
                        width:isMobile ? "100%" : "93%",
                        marginTop:isMobile ? "1.5rem" : "1.6rem",
                        padding: "0.5rem 1rem",
                        borderRadius:"32px",
                        flexGrow:1,
                        overflow:isMobile ? null : "auto",
                        background:"#EA723C1A",
                        height:isMobile ? null : "25%",
                    }}
                >
                    <div
                        style={{
                            marginLeft:"1rem",
                            fontSize:"1.5rem",
                            fontWeight:"800",
                            color:"#EA723C",
                            marginTop:"1rem"
                        }}
                    >What we offer</div>
                    <div className="home-grid">
                        {
                            features.map((item, index)=>(
                                <div key={index} className="home-grid-item">
                                    <p style={{width:"20px", height:"18px", margin:0}}>
                                        <CheckIcon color="#EA723C" /> 
                                    </p> 
                                    <div style={{marginLeft:"0.5rem", color:"#1E1E1E"}}>{item}</div>
                                </div>
                            ))
                        }
                    </div>
                </Paper>                
            </div>
        </>
    )
}

export default HowItWorks;