const FAQsIcon = ({color="#1E1E1E", ...props}) =>{
    return (
        <svg width="18" height="18" {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.20312 15.3992C7.04343 16.095 8.05473 16.5382 9.21394 16.6143C10.1273 16.6742 11.0808 16.6741 11.9923 16.6143C12.3062 16.5937 12.6483 16.5198 12.943 16.4C13.2708 16.2668 13.4347 16.2001 13.518 16.2103C13.6014 16.2204 13.7222 16.3083 13.964 16.4842C14.3902 16.7944 14.9272 17.0172 15.7235 16.9981C16.1262 16.9884 16.3275 16.9836 16.4176 16.8319C16.5078 16.6803 16.3955 16.4703 16.171 16.0505C15.8596 15.4682 15.6623 14.8015 15.9613 14.2674C16.4761 13.5044 16.9135 12.6009 16.9774 11.625C17.0117 11.1007 17.0117 10.5577 16.9774 10.0334C16.9348 9.38314 16.777 8.76503 16.5241 8.19922" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.27601 13.3894C12.1205 13.2021 14.3862 10.9255 14.5726 8.06754C14.6091 7.50825 14.6091 6.92904 14.5726 6.36975C14.3862 3.51175 12.1205 1.23521 9.27601 1.04788C8.3056 0.983974 7.29243 0.984105 6.32399 1.04788C3.47954 1.23521 1.2138 3.51175 1.02736 6.36975C0.990879 6.92904 0.990879 7.50825 1.02736 8.06754C1.09526 9.10846 1.55993 10.0722 2.10698 10.8861C2.42461 11.4558 2.21499 12.1669 1.88414 12.788C1.6456 13.2359 1.52633 13.4598 1.62209 13.6215C1.71786 13.7833 1.93178 13.7885 2.35961 13.7988C3.20569 13.8192 3.77623 13.5816 4.2291 13.2507C4.48595 13.063 4.61438 12.9692 4.7029 12.9584C4.79141 12.9477 4.9656 13.0187 5.31392 13.1609C5.62699 13.2886 5.9905 13.3674 6.32399 13.3894C7.29243 13.4532 8.3056 13.4533 9.27601 13.3894Z" stroke={color} strokeLinejoin="round"/>
            <path d="M7 5.42999C7 4.75025 7.53726 4.19922 8.2 4.19922C8.86274 4.19922 9.4 4.75025 9.4 5.42999C9.4 5.675 9.33019 5.9033 9.20987 6.09509C8.85124 6.66671 8.2 7.21179 8.2 7.89153V8.19922" stroke={color} strokeLinecap="round"/>
            <path d="M8.19922 10H8.2082" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default FAQsIcon;