import { CircularProgress } from "@mui/material";

const CircularLoading = ()=>{
    return (
        <div
            style={{
                width:"100%",
                height:"100%",
                display:"flex",
                position:"absolute",
                alignItems:"center",
                justifyContent:"center"
            }}
        >
            <CircularProgress />
        </div>
    )
}

export default CircularLoading;