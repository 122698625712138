const HowItWorksIcon = ({color="#1E1E1E", ...props}) =>{
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3346 9.9987C18.3346 5.39633 14.6037 1.66536 10.0013 1.66536C5.39893 1.66536 1.66797 5.39633 1.66797 9.9987C1.66797 14.6011 5.39893 18.332 10.0013 18.332C14.6037 18.332 18.3346 14.6011 18.3346 9.9987Z" stroke={color}/>
            <path d="M10.2044 14.166V9.99935C10.2044 9.60651 10.2044 9.41009 10.0824 9.28805C9.96035 9.16602 9.76393 9.16602 9.37109 9.16602" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.99593 6.66602H10.0034" stroke={color} strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default HowItWorksIcon;