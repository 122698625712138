const DocumentTypeIcon = ({color="#6D6D6D", ...params}) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.1" width="32" height="32" rx="6" fill="black"/>
        <path d="M23.4 15.2V14.4C23.4 11.383 23.4 9.87452 22.4076 8.93726C21.4152 8 19.818 8 16.6235 8L15.7765 8C12.582 8 10.9848 8 9.99239 8.93726C9 9.87452 9 11.383 9 14.4L9 17.6C9 20.617 9 22.1255 9.99239 23.0627C10.9848 24 12.582 24 15.7765 24H16.2" stroke={color} strokeLinecap="round"/>
        <path d="M13 12H19.4" stroke={color} strokeLinecap="round"/>
        <path d="M13 16H17" stroke={color} strokeLinecap="round"/>
        <path d="M23.3984 22.9173L23.3984 19.9996C23.3984 18.8561 22.3239 17.5996 20.9984 17.5996C19.673 17.5996 18.5984 18.8561 18.5984 19.9996L18.5984 22.7996C18.5984 23.4234 19.1845 23.9996 19.9075 23.9996C20.6305 23.9996 21.2166 23.4234 21.2166 22.7996L21.2166 20.6114" stroke={color} strokeLinecap="round"/>
        </svg>
    )
}

export default DocumentTypeIcon;