import "../styles/login.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";

import AppLogo from "../icons/AppLogo";
import socket from "../socket";
import LoginPageImage from "../icons/LoginPageImage";
import { CustomTextField } from "./CustomMUIInputs";
import useResponsiveView from "../hooks/useResponsiveView";

const COMPANY_URL = "https://lemolite.com"

const Login = () => {
    const isMobile = useResponsiveView();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();
    
    const handleLogin = (event) => {
        event.preventDefault();
        setEmailError("");
        setPasswordError("");
        fetch('/auth/login', {
            method:'post',
            headers: {
                'Authorization' : 'Basic ' + btoa(email + ':' + password),
                'Content-Type' : 'application/json'
            },
            body:JSON.stringify({sid:socket.id})
        })
        .then((response)=>{
            if(response.status === 200){
                navigate("/");
                // toast.success("Login Successful", {closeOnClick:true});
            }else{
                response.json()
                .then((message)=>{
                    if(response.status === 401){
                        if(message.includes("user")){
                            setEmailError("User does not exist!");
                        }else if(message.includes("password")){
                            setPasswordError("Invalid Password!");
                        }else if(message.includes('pending')){
                            toast.warning("Pending Verification", {closeOnClick:true});
                        }
                    }else if(response.status === 403){
                        toast.error("Access Denied", {closeOnClick:true});
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [animationClass, setAnimationClass] = useState("circular-slide-in-up");
    const [words, ] = useState(["EASY!", "EFFECTIVE!", "ACCURATE!", "FASTER!"])
    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationClass("circular-slide-out-up"); 

            setTimeout(() => {
                setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length); 
                setAnimationClass("circular-slide-in-up"); 
            }, 500); 
        }, 1500); 

        return () => clearInterval(interval);
    }, [currentWordIndex]);

    return (
        <>
            <div className="login-container">
                <div className="login-left">
                    <div className="login-left-inner">
                        <AppLogo className={"login-app-logo"} />
                        {
                            isMobile ?
                            <div className="login-image-container">
                                <LoginPageImage className="login-image" />
                            </div>
                            : null
                        }
                        <div className="login-form">
                            <div className="login-header">
                                <h3>Login</h3>
                                <p>Let's get started <span className="wave-emoji">👋</span></p>
                            </div>
                            <form onSubmit={handleLogin} className="login-form-box">
                                <CustomTextField 
                                    variant="outlined"
                                    label={"Email Address"}
                                    placeholder="Enter your Email ID"
                                    name="email"
                                    id="email"
                                    sx={{    
                                        fontFamily : "Montserrat",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius:"10px",
                                            border: "2px solid #3F3F3F"
                                        }
                                    }}      
                                    required
                                    error={!!emailError}
                                    helperText={emailError}
                                    value={email}
                                    onChange={(event)=>setEmail(event.target.value)}
                                />
                                <CustomTextField 
                                    variant="outlined"
                                    label={"Password"}
                                    placeholder="Enter your password"
                                    name="password"
                                    type="password"
                                    id="password"
                                    sx={{
                                        fontFamily : "Montserrat",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius:"10px",
                                            border: "2px solid #3F3F3F"
                                        }
                                    }}
                                    required
                                    error={!!passwordError}
                                    helperText={passwordError}
                                    value={password}
                                    onChange={(event)=>setPassword(event.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    sx={{
                                        margin:isMobile ? "1rem 0 0.5rem 0" : "3rem 0 1rem 0",
                                        background:"#EA723C",
                                        borderRadius:"1rem",
                                        textTransform:"none",
                                        fontFamily:"Montserrat",
                                        fontSize:"1.1rem",
                                        fontWeight:"600",
                                        width:"100%",
                                        height:"3rem",
                                        "&:hover" : {
                                            background : "#EA723C",
                                            boxShadow:"2px 2px 5px 1px #EA723C"
                                        }
                                    }}
                                    type="submit"
                                >
                                    Login
                                </Button>
                            </form>
                        </div>
                        <footer>Powered by <Link to={COMPANY_URL} target="blank"><strong>Lemolite Technologies LLP</strong></Link></footer>
                    </div>
                </div>
                {
                isMobile ? null :
                <div className="login-right">
                    <div className="login-quote">
                        <h3>Make Your <br /> Hiring Process</h3>
                        <div className="word-container">
                            <h2 className={`login-subheading ${animationClass}`}>
                                {words[currentWordIndex]}
                            </h2>
                        </div>
                    </div>
                    <div className="login-image-container">
                        <LoginPageImage className="login-image" />
                    </div>
                </div>
                }
            </div>
        </>
    )
}

export default Login;