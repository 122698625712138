const CheckIcon = ({color="#BBD4F0", ...params}) => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="9.51583" cy="8.93916" rx="9.51583" ry="8.93916" fill={color}/>
            <path d="M6.40235 7.77091L6.40237 7.7709L6.40108 7.76874C6.33272 7.65355 6.24458 7.5647 6.13753 7.50886C6.03017 7.45285 5.9158 7.43633 5.80552 7.44709C5.59174 7.46794 5.39045 7.59039 5.23381 7.73586C5.07453 7.88379 4.9389 8.07601 4.86365 8.2736C4.79102 8.46429 4.76138 8.69918 4.87978 8.89788L7.04825 12.883L7.05742 12.8999L7.06964 12.9147C7.30093 13.1951 7.64698 13.2386 7.97532 13.1729C8.30315 13.1074 8.65362 12.9286 8.96848 12.7025L9.01452 12.6695L9.03637 12.6172C10.5658 8.95674 12.0602 6.62918 13.9981 4.57948C14.1185 4.46352 14.2068 4.32329 14.1996 4.16876C14.1916 3.99619 14.0711 3.88725 13.9484 3.83883C13.7177 3.74782 13.3928 3.81644 13.1525 4.03965C11.4404 5.40418 9.57128 7.71884 7.89469 10.3592L6.40235 7.77091Z" fill="white" stroke="white" strokeWidth="0.4"/>
        </svg>

    )
}

export default CheckIcon;