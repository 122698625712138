const RankAndAnalyzeIcon = ({color="#1E1E1E", ...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.41797 14.584L5.41797 12.084M9.58464 14.584L9.58464 7.08398M13.7513 14.584V11.2507" stroke={color} strokeLinecap="round"/>
            <path d="M17.918 4.58398C17.918 5.9647 16.7987 7.08398 15.418 7.08398C14.0373 7.08398 12.918 5.9647 12.918 4.58398C12.918 3.20327 14.0373 2.08398 15.418 2.08398C16.7987 2.08398 17.918 3.20327 17.918 4.58398Z" stroke={color}/>
            <path d="M17.9136 9.16732C17.9136 9.16732 17.9173 9.45026 17.9173 10.0007C17.9173 13.7326 17.9173 15.5986 16.7579 16.758C15.5986 17.9173 13.7326 17.9173 10.0007 17.9173C6.2687 17.9173 4.40272 17.9173 3.24335 16.758C2.08398 15.5986 2.08398 13.7326 2.08398 10.0007C2.08398 6.26872 2.08398 4.40275 3.24335 3.24338C4.40272 2.08401 6.2687 2.08401 10.0007 2.08401L10.834 2.08398" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>      
    )
}
export default RankAndAnalyzeIcon;
