const JobDescriptionsIcon = ({color="#1E1E1E", ...props}) => {
    return ( 
        <svg width="20" height="20" viewBox="0 0 20 20" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_5077_6288" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect x="0.5" y="0.5" width="19" height="19" fill="#D9D9D9" stroke={color}/>
        </mask>
        <g mask="url(#mask0_5077_6288)">
        <path d="M14 15L6 15" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.7273 18H10C6.22876 18 4.34315 18 3.17157 17.0561C2 16.1123 2 14.5931 2 11.5548V6.5745C2 5.21035 2 4.52828 2.2522 3.98352C2.65765 3.10775 3.5151 2.41695 4.60214 2.0903C4.94321 1.98782 7.9181 1.98872 11.0003 2.01479C14.3139 2.04281 15.9706 2.05682 16.9853 3.08012C18 4.10342 18 5.76572 18 9.0903V12.1408C18 14.6136 18 15.85 17.2022 16.7086C16.9736 16.9547 16.7025 17.1731 16.3971 17.3572C15.3313 18 13.7966 18 10.7273 18Z" stroke={color} strokeLinejoin="round"/>
        <path d="M10 9.26172L10 9.8932" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.21094 7.58008L6.27531 8.78548C6.34447 10.3068 6.37905 11.0675 6.86729 11.534C7.35554 12.0005 8.11711 12.0005 9.64025 12.0005H10.3606C11.8837 12.0005 12.6453 12.0005 13.1335 11.534C13.6218 11.0675 13.6564 10.3068 13.7255 8.78548L13.7899 7.58008" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.14618 7.34313C6.86168 8.70351 8.47545 9.26156 10 9.26156C11.5245 9.26156 13.1383 8.70351 13.8538 7.34313C14.1954 6.69376 13.9367 5.47266 13.0956 5.47266H6.90442C6.06326 5.47266 5.80464 6.69376 6.14618 7.34313Z" stroke={color} strokeWidth="0.8"/>
        <path d="M11.6848 5.47346L11.6476 5.34335C11.4624 4.69501 11.3697 4.37084 11.1492 4.18542C10.9287 4 10.6357 4 10.0499 4H9.95137C9.3655 4 9.07256 4 8.85203 4.18542C8.63149 4.37084 8.53886 4.69501 8.35359 5.34335L8.31641 5.47346" stroke={color} strokeWidth="0.8"/>
        </g>
        </svg>

    )
}

export default JobDescriptionsIcon;
