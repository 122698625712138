import { 
    Dialog, DialogActions, DialogContent, DialogTitle,  
    Button, IconButton, ToggleButton, ToggleButtonGroup, Tooltip, 
    InputLabel, MenuItem, Select, Switch, TextField, FormControl, 
    DialogContentText,
    ButtonGroup,
    Paper
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import "../styles/users.css";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "../styles/agtable.css";

import ResetPasswordIcon from "../icons/ResetPasswordIcon";
import DeleteIcon from "../icons/DeleteIcon";
import PlusIcon from "../icons/PlusIcon";
import socket from "../socket";
import { toast } from "react-toastify";
import EditIcon from "../icons/EditIcon";
import CrossCircleIcon from "../icons/CrossCircleIcon";
import DeleteCofirmIcon from "../icons/DeleteConfirmIcon";
import FillButton from "./FillButton";
import FileUploadIcon from "../icons/FileUploadIcon";
import FileDeleteIcon from "../icons/FileDeleteIcon";
import EditUserIcon from "../icons/EditUserIcon";
import { CustomSelect, CustomTextField } from "./CustomMUIInputs";
import Lottie from "react-lottie";
import deleteAnimation from "../animations/deleteAnimation.json";
import useResponsiveView from "../hooks/useResponsiveView";
import CustomGridPagination from "./CustomGridPagination";

const nameRenderer = (params)=>{
    return (
        <div
            style={{
                fontSize : "1rem",
                padding:"0"
            }}
        >
            <div style={{height:"25px", fontWeight:"500"}}>{params.data.firstname} {params.data.lastname}</div>
            <div
                style={{
                    color:"gray"
                }}
            >{params.data.username}</div>
        </div>
    )
}

const statusRenderer = (params) => {
    const renderMap = {
        null : {
            text : "Pending",
            background : '#FFF1D6',
            color : '#B86F01'
        },
        true : {
            text : "Active",
            background : '#DBF6E5',
            color : '#1A925D'
        },
        false : {
            text : "Banned",
            background : '#FFE4DE',
            color : "#C4423D"
        }
    }
    return (
        <div className="centered-cell">
            <div
                style={{
                    width:"70%",
                    height:"45%",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    background : renderMap[params.data.verified].background,
                    color : renderMap[params.data.verified].color,
                    borderRadius:"0.5rem",
                    fontSize:"1rem",
                    fontWeight:500
                }}
            >
                {renderMap[params.data.verified].text}
            </div>
        </div>
    )
}

const permissionRenderer = (params)=>{
    return (
        <Switch
            disabled={params.data.role === 'superadmin'}
            checked={params.data.verified} 
            style={{
                color: params.data.role !== 'superadmin' ? '#EA723C' : 'lightgray'
            }}
            sx={{
                '& .MuiSwitch-thumb': {
                    padding:0.5,
                    width: 10,
                    height: 10,
                    backgroundColor: 'white',
                    border: params.data.role !== 'superadmin' ? "1px solid #EA723C" : "lightgray"
                },
                '& .MuiSwitch-track': {
                    backgroundColor: '#CDCDCD',
                },
                '& .Mui-checked+.MuiSwitch-track' : {
                    backgroundColor: "#EA723C"
                }
            }}  
            onClick={(event)=>{
                socket.emit("update-user", {_id:params.data._id, update:{verified:!params.value}});
            }}
        />
    )
}

const accessRenderer = (params)=>{
    const supperAccess = params.data.role === 'superadmin';
    return (
        <div
            style={{
                display:"flex"
            }}
        >
            <div
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor:supperAccess ? "not-allowed" : "pointer",
                    pointerEvents:supperAccess ? "none" : undefined
                }}
                onClick={(event)=>{
                    socket.emit("update-user", {_id:params.data._id, update:{access_post:!params.data.access_post}});
                }}
            >
                {
                params.data.access_post ? 
                    <FileUploadIcon color={supperAccess ? 'lightgray' :"#EA723C"} width={"15"} /> 
                    : 
                    <FileUploadIcon color={supperAccess ? 'lightgray' :"#6D6D6D"} width={"15"}/>
                }
                <p
                    style={{
                        color:supperAccess ? 'lightgray' :params.data.access_post ? "#EA723C" : "#6D6D6D",
                        marginLeft:"0.3rem",
                        fontWeight:500,
                        fontSize:"0.8rem"
                    }}
                >Upload</p>
            </div>

            <div
                style={{
                    marginLeft:"1.2rem",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    cursor:supperAccess ? "not-allowed" : "pointer",
                    pointerEvents:supperAccess ? "none" : undefined
                }}
                onClick={(event)=>{
                    socket.emit("update-user", {_id:params.data._id, update:{access_delete:!params.data.access_delete}});
                }}
            >
                {
                params.data.access_delete ? 
                    <FileDeleteIcon color={supperAccess ? 'lightgray' :"#EA723C"} width={"15"} /> 
                    : 
                    <FileDeleteIcon color={supperAccess ? 'lightgray' :"#6D6D6D"} width={"15"}/>
                }
                <p
                    style={{
                        color:supperAccess ? 'lightgray' :params.data.access_delete ? "#EA723C" : "#6D6D6D",
                        marginLeft:"0.3rem",
                        fontWeight:500,
                        fontSize:"0.8rem"
                    }}
                >Delete</p>
            </div>
        </div>
    )
}

const ActionsRenderer = (params)=>{
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    
    const handleCloseDelete = () => {
        setIsDeleteOpen(false);
    }
    const handleCloseEdit = () => {
        setIsEditOpen(false);
    }

    const handleUserEdit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formJson = Object.fromEntries(formData.entries());
        socket.emit("update-user", {_id:params.data._id, update:formJson});
        handleCloseEdit();
    }

    return (
        <>
            <Dialog fullWidth open={isDeleteOpen} onClose={handleCloseDelete} 
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black"
                    }}
                >
                    <div>Delete User</div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                    }}
                >
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                    <DialogContentText
                        style={{
                            fontFamily:"Montserrat",
                            marginTop:"0.5rem",
                            color:"black",
                            fontWeight:"600",
                            textAlign:"center",
                            width:"80%"
                        }}
                    >Are you sure you want to delete the user {params.data.username}?</DialogContentText>
                </DialogContent>
                <DialogActions style={{display:"flex", justifyContent:"space-evenly"}}>
                    <Button 
                        variant="outlined"
                        style={{
                            fontWeight:600, 
                            fontSize:"1rem",
                            background:"white",
                            border:"1px solid black",
                            color:"black",
                            width:"12rem",
                            textTransform : 'none',
                            borderRadius:"16px"
                        }} 
                        onClick={()=>{
                            handleCloseDelete();
                        }}
                    >No</Button>
                    <Button 
                        variant="outlined"
                        style={{
                            fontWeight:600, 
                            fontSize:"1rem",
                            background:"#EA723C",
                            border:"1px solid #EA723C",
                            color:"white",
                            width:"12rem",
                            textTransform : 'none',
                            borderRadius:"16px"
                        }} 
                        onClick={()=>{
                            socket.emit('delete-user', {_id:params.data._id});
                            handleCloseDelete();
                        }}
                    >Yes</Button>
                </DialogActions>
            </Dialog>
            
            <Dialog fullWidth open={isEditOpen} onClose={handleCloseEdit}
                PaperProps={{
                    style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                    component:"form",
                    onSubmit:handleUserEdit
                }}
            >
                <DialogTitle
                    style={{
                        textAlign:"center",
                        fontFamily:"Montserrat",
                        fontWeight:"700",
                        color:"black",
                        position:"relative"
                    }}
                >
                    <div>Edit User</div>
                    <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={handleCloseEdit}>
                        <CrossCircleIcon color="black" height="25" width="25" />
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                    }}
                >
                    <CustomTextField
                        fullWidth
                        label="Username"
                        margin="normal"
                        defaultValue={params.data.username}
                        disabled
                    />

                    <CustomTextField
                        fullWidth
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        margin="normal"
                        defaultValue={params.data.firstname}
                    />

                    <CustomTextField
                        fullWidth
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        margin="normal"
                        defaultValue={params.data.lastname}
                    />

                    <CustomSelect
                        labelId="role-label"
                        id="role"
                        name="role"
                        label="Role"
                        defaultValue={params.data.role}
                        options={params?.roles}
                        style={{
                            borderRadius:"10px",
                            textTransform:"capitalize",
                            fontFamily:"Montserrat"
                        }}
                        placeholder={"Select Role"}
                        required
                    />
                </DialogContent>
                <DialogActions style={{display:"flex", justifyContent:"center"}}>
                    <Button 
                        type="submit"
                        variant="contained"
                        style={{
                            background:"#EA723C",
                            textTransform:"none",
                            width:"100%",
                            height:"2.8rem",
                            margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                            borderRadius:"6px",
                            fontWeight:"600"
                        }}
                    >Edit</Button>
                </DialogActions>
            </Dialog>

            {
                params.data.role === 'superadmin' ? 
                <IconButton disabled>
                    <EditUserIcon color='lightgray'/> 
                </IconButton> :
                <Tooltip title="Edit" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            setIsEditOpen(true);
                        }}
                    >
                        <EditUserIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            }

            {
                params.data.role === 'superadmin' ? 
                <IconButton disabled>
                    <DeleteIcon color='lightgray'/> 
                </IconButton> :
                <Tooltip title="Delete" arrow>
                    <IconButton
                        className="action-btn"
                        onClick={(event)=>{
                            setIsDeleteOpen(true);
                        }}
                    >
                        <DeleteIcon className="action-svg-icon" />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

const generatePassword = ()=>{
    let password = "";
    const letters = '0123456789abcdef';
    for(let i=0; i<6; i++){
        password += letters[Math.floor(Math.random() * letters.length)]
    }
    return password;
}

const AddUserDialog = ({open, users, roles, onClose, onSubmit}) => {
    const [defaultPassword, setDefaultPassword] = useState("");
    const [error, setError] = useState("");
    const isMobile = useResponsiveView();
    
    const handleSubmit = (event)=>{
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        if(users.some(user=>user.username===formJson.email)){
            setError(`Email ${formJson.email} already registered`);
            return;
        }
        onSubmit(event);
    }
    
    useEffect(()=>{
        const password = generatePassword();
        setDefaultPassword(password);
        setError("");
    }, [open]);

    useEffect(()=>{
        socket.on("reset-password-done", ()=>{
            toast.success("Reset password successfully", {closeOnClick:true});
        })
        return ()=>{
            socket.off("reset-password-done");
        }
    }, []);

    return (
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:handleSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>Add User</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
                {/* <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div> */}
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <CustomTextField name="firstname" placeholder="Enter First Name" id="firstname" label="First Name" margin={isMobile ? "dense" : "normal"} required defaultValue={""}
                    className="add-user-field"
                />
                
                <CustomTextField name="lastname" placeholder="Enter Last Name" id="lastname" label="Last Name" margin={isMobile ? "dense" : "normal"} required defaultValue={""}
                    className="add-user-field"
                />
                
                <CustomTextField name="email" id="email" placeholder="Enter Email" type="email" label="Email Address" margin={isMobile ? "dense" : "normal"} required defaultValue={""}
                    className="add-user-field"
                    error={!!error}
                    helperText={error}
                />

                <CustomTextField name="password" placeholder="Enter Password" id="password" label="Password" margin={isMobile ? "dense" : "normal"} required defaultValue={defaultPassword}
                    className="add-user-field"
                />

                <CustomSelect 
                    options={roles}
                    id="role"
                    name="role"
                    label="Role"
                    style = {{
                        textTransform:"capitalize"
                    }}
                    defaultValue=""
                    placeholder="Select Role"  
                />
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                <Button 
                    type="submit"
                    variant="contained"
                    style={{
                        background:"#EA723C",
                        textTransform:"none",
                        width:"100%",
                        height:"2.8rem",
                        margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                        borderRadius:"6px",
                        fontWeight:"600"
                    }} 
                >Add</Button>
            </DialogActions>
        </Dialog>
    )
}

const ResetUserPasswordDialog = ({users, open, onClose, onSubmit}) => {
    const [defaultPassword, setDefaultPassword] = useState("");
    const isMobile = useResponsiveView();
    useEffect(()=>{
        const password = generatePassword();
        setDefaultPassword(password);
    }, [open])

    return (
        <Dialog fullWidth open={open} onClose={onClose} 
            PaperProps={{
                style:{borderRadius:"1rem", padding:"1rem", background:"white", maxWidth:"30rem"},
                component:"form",
                onSubmit:onSubmit
            }}
        >
            <DialogTitle
                style={{
                    textAlign:"center",
                    fontFamily:"Montserrat",
                    fontWeight:"700",
                    color:"black",
                    position:"relative"
                }}
            >
                <div>Reset User Password</div>
                <div style={{position:"absolute", top:"1rem", right:"1rem", cursor:"pointer"}} onClick={onClose}>
                    <CrossCircleIcon color="black" height="25" width="25" />
                </div>
            </DialogTitle>
            <DialogContent
                style={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >  
                <CustomSelect
                    id="user"
                    name="user"
                    label="User"
                    placeholder={"Select User"}
                    defaultValue={""}
                    options={users.filter(usr=>(usr.role !== 'superadmin')).map(usr=>usr.username)}
                    menuItemStyle={{
                        textTransform:"none"
                    }}
                    margin={isMobile ? "dense" : "normal"} required
                />
                <p style={{height:"0.5rem", margin:"0"}}></p>
                <CustomTextField name="password" id="password" label="Password" margin={isMobile ? "dense" : "normal"} required defaultValue={defaultPassword}
                    className="add-user-field"
                    InputProps={{
                        style:{borderRadius:"10px", fontFamily:"Montserrat"}
                    }}
                />            
            </DialogContent>
            <DialogActions style={{display:"flex", justifyContent:"center"}}>
                <Button 
                    type="submit"
                    variant="contained"
                    style={{
                        background:"#EA723C",
                        textTransform:"none",
                        width:"100%",
                        height:"2.8rem",
                        margin:"0.25rem 1.5rem 0.5rem 1.5rem",
                        borderRadius:"6px",
                        fontWeight:"600"
                    }} 
                >Reset</Button>
            </DialogActions>
        </Dialog>
    )
}

const Users = ({users}) => {
    const isMobile = useResponsiveView();
    const [openUserAdd, setOpenUserAdd] = useState(false);
    const [openPasswordReset, setOpenPasswordReset] = useState(false);
    const [roles, setRoles] = useState([]);
    const gridAPIRef = useRef(null);
    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            cellRenderer:nameRenderer,
            filterValueGetter : (params)=>params.data.firstname + ' ' + params.data.lastname + ' ' + params.data.username,
            comparator : (valueA, valueB, nodeA, nodeB, isDescending)=>{
                const stringA = `${nodeA.data.firstname} ${nodeA.data.lastname}`;
                const stringB = `${nodeB.data.firstname} ${nodeB.data.lastname}`;
                return stringA.localeCompare(stringB);
            }
        },
        { field:'role', headerName: 'Role', sortable: true, filter: true, cellClass:"user-role" },
        { 
            headerName: 'Status', sortable: true, filter: true, cellRenderer:statusRenderer,
            valueGetter: (params)=>{
                return params.data.verified === null ? 'Pending' : params.data.verified ? 'Active' : 'Banned'
            }
        },
        { headerName: 'Permission', field:'verified', cellRenderer:permissionRenderer, cellClass:"centered-cell" },
        { headerName: 'Access', cellRenderer:accessRenderer, cellClass:"centered-cell" },
        { headerName: 'Actions', cellRenderer:ActionsRenderer, cellClass:"centered-cell", cellRendererParams:{roles:roles} },
    ])

    useEffect(()=>{
        socket.emit("request-roles");
        socket.on("update-roles", (data)=>{
            setRoles(data);
            setColumnsDefs(prev=>prev.map(colDef=>{
                return colDef.headerName === 'Actions' ?
                {...colDef, cellRendererParams:{roles:data}}
                : colDef
            }))
        });

        return ()=>{
            socket.off("update-roles");
        }

    }, [users]);
    
    return (
        <>
            {
                isMobile ? 
                // Mobile View
                <div className="home-container" style={{height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"}}>
                    <AddUserDialog 
                        open={openUserAdd}
                        users={users}
                        roles={roles}
                        onClose={()=>setOpenUserAdd(false)}
                        onSubmit={(event)=>{
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            socket.emit('add-user', formJson);
                            setOpenUserAdd(false);
                        }}
                    />

                    <ResetUserPasswordDialog 
                        users={users || []}
                        open={openPasswordReset}
                        onClose={()=>setOpenPasswordReset(false)}
                        onSubmit={(event)=>{
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            socket.emit('update-user', {
                                "username" : formJson.user,
                                "update" : {password:formJson.password}
                            });
                            setOpenPasswordReset(false);
                        }}
                    />
                    
                    <div className="users-header-buttons">
                        <FillButton 
                            variant="outlined" 
                            className={"add-user-btn"}
                            startIcon={<PlusIcon className="adduser-svg-icon" />}
                            onClick={()=>setOpenUserAdd(true)}
                            value={"Add User"}
                        />    
                        
                        <FillButton 
                            variant="outlined" 
                            className={"reset-password-btn"}
                            startIcon={<ResetPasswordIcon className="resetpassword-svg-icon" />}
                            onClick={()=>setOpenPasswordReset(true)}
                            value={"Reset User Password"}
                        />    
                    </div>

                    <div className={"ag-theme-quartz" + (isMobile ? " mobile" : "")} style={{ width: '100%', borderRadius:"16px", flexGrow:"1", margin:"1rem", overflow:"auto" }}>
                        <AgGridReact 
                            getRowId={(params)=>params.data._id}
                            pagination={true} 
                            defaultColDef={{wrapHeaderText:true, minWidth:150, flex:3, resizable: false, sortable:false, cellStyle:{textAlign:"center"}, headerClass:"centered-header"}}
                            rowData={users} 
                            columnDefs={columnDefs}
                            rowHeight={"70px"}
                            suppressCellFocus
                            onGridReady={(params)=>gridAPIRef.current = params.api}
                            suppressPaginationPanel
                            paginationPageSize={100}
                        />
                    </div>
                    <CustomGridPagination gridAPI={gridAPIRef.current} />
                </div>
                :

                // Desktop View
                <div className="users-container">
                    <AddUserDialog 
                        open={openUserAdd}
                        roles={roles}
                        users={users}
                        onClose={()=>setOpenUserAdd(false)}
                        onSubmit={(event)=>{
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            socket.emit('add-user', formJson);
                            setOpenUserAdd(false);
                        }}
                    />

                    <ResetUserPasswordDialog 
                        users={users || []}
                        open={openPasswordReset}
                        onClose={()=>setOpenPasswordReset(false)}
                        onSubmit={(event)=>{
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            socket.emit('update-user', {
                                "username" : formJson.user,
                                "update" : {password:formJson.password}
                            });
                            setOpenPasswordReset(false);
                        }}
                    />

                    <Paper
                        elevation={1}
                        sx={{
                            width:"93%",
                            marginTop:"0.5rem",
                            padding:"0.5rem 1rem",
                            height:"100%",
                            borderRadius:"32px",
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >

                        <div className="users-header-buttons">
                            <FillButton 
                                variant="outlined" 
                                className={"add-user-btn"}
                                startIcon={<PlusIcon className="adduser-svg-icon" />}
                                onClick={()=>setOpenUserAdd(true)}
                                value={"Add User"}
                            />    
                            
                            <FillButton 
                                variant="outlined" 
                                className={"reset-password-btn"}
                                startIcon={<ResetPasswordIcon className="resetpassword-svg-icon" />}
                                onClick={()=>setOpenPasswordReset(true)}
                                value={"Reset User Password"}
                            />    
                        </div>

                        <div className="ag-theme-quartz" style={{ width: 'calc(1rem + 100%)', height:"100%", marginLeft:"-0.5rem" }}>
                            <AgGridReact 
                                getRowId={(params)=>params.data._id}
                                pagination={true} 
                                defaultColDef={{wrapHeaderText:true, minWidth:100, flex:3, resizable: false, sortable:false, cellStyle:{textAlign:"center"}, headerClass:"centered-header"}}
                                rowData={users} 
                                columnDefs={columnDefs}
                                rowHeight={"70px"}
                                suppressCellFocus
                                onGridReady={(params)=>gridAPIRef.current = params.api}
                                suppressPaginationPanel
                                paginationPageSize={100}
                            />
                        </div>
                        <CustomGridPagination gridAPI={gridAPIRef.current} />
                    </Paper>
                </div>
            }
        </>
    )
}

export default Users;