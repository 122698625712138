import "../styles/dashboard.css";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Divider, Paper, Drawer, IconButton, Button, Fade } from "@mui/material";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AppLogo from "../icons/AppLogo";
import HomeIcon from "../icons/HomeIcon";
import UsersIcon from "../icons/UsersIcon";
import ResumesIcon from "../icons/ResumesIcon";
import JobDescriptionsIcon from "../icons/JobDescriptionsIcon";
import RankAndAnalyzeIcon from "../icons/RankAndAnalyzeIcon";
import LogoutIcon from "../icons/LogoutIcon";

import socket from "../socket";
import Home from "./Home";
import HowItWorks from "./HowItWorks";
import Users from "./Users";
import DocumentList from "./DocumentList";
import RankAndAnalyze from "./RankAndAnalyze";
import ErrorPage from "./ErrorPage";
import SiteTour from "./SiteTour";
import HamburgerButtonIcon from "../icons/HamburgerButtonIcon";
import UploadStatusDialog from "./UploadStatusDialog";
import { useUploadQueue } from "../contexts/UploadQueueContext";
import DocumentContainer from "./DocumentContainer";
import HowItWorksIcon from "../icons/HowItWorksIcon";
import FAQsIcon from "../icons/FAQsIcon";
import FAQs from "./FAQs";
import useResponsiveView from "../hooks/useResponsiveView";

const MENUITEMS = [
    {
        name : "Home",
        icon : HomeIcon,
        path : '/',
        id : "homePageLink"
    },
    {
        name : "How It Works",
        icon : HowItWorksIcon,
        path : '/how-it-works',
        id : "hiwPageLink"
    },
    {
        name : "Users",
        icon : UsersIcon,
        path : '/users',
        autorization : true,
        id : "usersPageLink"
    },
    {
        name : "Resumes",
        icon : ResumesIcon,
        path: '/resumes',
        id : "resumesPageLink"
    },
    {
        name : "Job Descriptions",
        icon : JobDescriptionsIcon,
        path : '/job-descriptions',
        id : "jobDescriptionsPageLink"
    },
    {
        name : "Rank & Analyze",
        icon : RankAndAnalyzeIcon,
        path : '/rank-and-analyze',
        id : "rankAndAnalyzePageLink"
    },
    {
        name : "FAQs",
        icon : FAQsIcon,
        path : '/faqs',
        id : "faqsPageLink"
    }
]

const Dashboard = ({user}) => {
    const [menu, setMenu] = useState("..." );
    const [users, setUsers] = useState(null);
    const [tour, setTour] = useState(false);
    const [documents, setDocuments] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useResponsiveView();

    useEffect(()=>{
        const seen_tour = localStorage.getItem("seenTour")
        if(!seen_tour && !user.seen_help){
            setTour(!user.seen_help);
            localStorage.setItem("seenTour", "true")
        }
        setMenu(MENUITEMS.find(x=>x.path === location.pathname)?.name || "Home")
        if(user.role === 'superadmin'){
            socket.emit("request-users");
            socket.on("update-users", (usersData)=>{
                setUsers(usersData);
            })
        }
        socket.emit("request-documents")
        socket.on("update-documents", (documentsData)=>{
            setDocuments(documentsData);
        })
        return ()=>{
            socket.off("update-users");
            socket.off("update-documents");
        }
    }, [user, location]);

    const handleLogout = () => {
        fetch('/auth/logout')
        .then((response=>{
            if(response.status === 200){
                // toast.success("Logout Successful", {closeOnClick:true});
                navigate('/auth/login');
            }
            else if(response.status === 401){
                toast.error("Session Expired", {closeOnClick:true});
                navigate("/auth/login");
            }
            else{
                console.log(response);
            }
        }))
        .catch((error)=>{
            console.log(error);
        })

        // remove data from localStorage
        localStorage.clear("seenTour");
    }

    // Mobile Services
    const [drawerOpen, setDrawerOpen] = useState(false);
    
    // Upload Status Dialog
    const [uploadDialog, setUploadDialog] = useState('');
    const {resumeQueue, jdQueue, setResumeQueue, setJdQueue} = useUploadQueue();
    
    return (
        <>
        <div className="upload-status-container-wrapper">
            <UploadStatusDialog 
                title={'resume'} 
                active={(uploadDialog === 'resume')} 
                onExpand={()=>{setUploadDialog('resume')}} 
                queue={resumeQueue} 
                onClose={()=>{
                    setResumeQueue([]);
                    setUploadDialog("");
                }}
            />
            <UploadStatusDialog 
                title={'job description'} 
                active={uploadDialog === 'job description'} 
                onExpand={()=>{setUploadDialog('job description')}} 
                queue={jdQueue} 
                onClose={()=>{
                    setJdQueue([]);
                    setUploadDialog("");
                }}
            />
        </div>
        {
            isMobile ? 
            // Mobile View
            <div className="dashboard-container-mobile">
                <Drawer
                    open={drawerOpen}
                    onClose={()=>setDrawerOpen(false)}
                    anchor="left"
                >
                    <div className="dashboard-mobile-drawer-container">
                        <AppLogo color="white" className={"dashboard-logo-mobile"} />
                        {
                            MENUITEMS.map((item, index)=>(
                                (!item.autorization || (user.role === 'superadmin')) ?
                                <Link key={index} to={item.path} style={{textDecoration:'none'}}
                                    id={item.id} 
                                    className={"menu-item-mobile" + (item.path === location.pathname ? " selected" : "")}
                                    onClick={()=>{
                                        setMenu(item.name);
                                        setDrawerOpen(false);
                                    }}    
                                >
                                    <div className="menu-item-icon mobile"><item.icon color={item.path === location.pathname ? "#EA723C" : 'white'}/></div>
                                    <div className="menu-item-content">{item.name}</div>
                                </Link>
                                : null
                                )  
                            )
                        }
                        <div
                            className="menu-item-mobile"
                            onClick={handleLogout}
                            style={{
                                position:"absolute",
                                bottom:"2rem"
                            }}
                        >
                            <div className="menu-item-icon mobile logout-btn-mobile"><LogoutIcon color={'#EA723C'} /></div>
                            <div className="menu-item-content">Logout</div>
                        </div>
                        <div
                            style={{
                                color:"white",
                                position:"absolute",
                                bottom:"0.75rem",
                                left:"1.1rem"
                            }}
                        >V 1.1</div>
                    </div>
                </Drawer>
                <div className="sticky-header-mobile">
                    <div className="dashboard-header-container-mobile">
                        <IconButton onClick={()=>setDrawerOpen(true)}>
                            <HamburgerButtonIcon color="#EA723C" margin="0" padding="0" />
                        </IconButton>

                        <div className="dashboard-box-header-user">
                                <div className="dahsboard-box-header-user-name" style={{fontSize:"1rem"}}>Hi, {user.firstname} {user.lastname}</div>
                                <div className="dahsboard-box-header-user-role" style={{fontSize:"0.8rem"}}>{user.role}</div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-mobile-box">
                    {
                        ["Home", "How it works"].includes(menu) ? null :
                        <h3
                            style={{
                                padding:"0",
                                margin:"0.5rem 1rem"
                            }}
                        >{menu}</h3>
                    }
                    <Routes>
                        <Route index path="/" element={<Home onTour={()=>setTour(true)} />}></Route>
                        <Route index path="/how-it-works" element={<HowItWorks />}></Route>
                        <Route path="users" element={
                            user.role === 'superadmin' ? <Users users={users} /> : <ErrorPage errorCode={403} errorText={"UnAuthorized"} />
                        }></Route>
                        <Route path="resumes" element={
                            <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                <DocumentList key='resumes' documentType={'resumes'} accessUpload={user.access_post} accessDelete={user.access_delete} />
                            </DocumentContainer>
                        }></Route>
                        <Route path="job-descriptions" element={
                            <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                <DocumentList key={'job-descriptions'} documentType={'job-descriptions'} accessUpload={user.access_post} accessDelete={user.access_delete} />
                            </DocumentContainer>
                        }></Route>
                        <Route path="rank-and-analyze" element={
                            <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                <RankAndAnalyze key={'rna'} />
                            </DocumentContainer>
                        }></Route>
                        <Route path="faqs" element={
                            <FAQs />
                        }></Route>
                    </Routes>
                </div>
            </div> 
            :
            
            // Desktop View
            <div className="dashboard-container">
                {
                    tour ? <SiteTour onEnd={()=>setTour(false)} /> : null
                }
                <div className="dashboard-overlay"></div>
                <div className="dashboard-drawer">
                    <AppLogo className="dashboard-drawer-logo" />
                    <p
                        style={{
                            position:"relative",
                            left:"10%",
                            width:"100%",
                            height:"1px",
                            background:"#B5B5B5",
                        }}
                    ></p>
                    {/* <Divider sx={{
                        color:"linea"
                    }} style={{height:'1px', margin:"1rem"}} variant="middle"/> */}
                    <div className="dashboard-drawer-core">
                        {
                            MENUITEMS.map((item, index)=>(
                                (!item.autorization || (user.role === 'superadmin')) ?
                                <Link key={index} to={item.path} style={{textDecoration:'none'}}
                                    id={item.id} 
                                    className={"menu-item" + (item.path === location.pathname ? " selected" : "")}
                                    onClick={()=>{
                                        setMenu(item.name);
                                    }}    
                                >
                                    <Paper className={"menu-item-inner-container" + (item.path === location.pathname ? " selected" : "")}
                                        elevation={0}
                                        sx={{
                                            background : item.path === location.pathname ? "#EA723C" : "#F8F9FA",
                                            zIndex : item.path === location.pathname ? 5 : 0,
                                            padding:"0.5rem",
                                            borderRadius:"16px",
                                            fontWeight:600,
                                            color:item.path === location.pathname ? "white" : "#1E1E1E",
                                            fontSize:"1rem",
                                            // boxShadow: item.path === location.pathname ? "0 0 10px 5px rgba(0, 0, 0, 0.1);" : "none"
                                        }}
                                    >
                                        <div className="menu-item-icon"
                                            style={{
                                                height:"2rem",
                                                width:"2rem",
                                                background:item.path === location.pathname ? "white" : "white",
                                                borderRadius:"10px",
                                                display:"flex",
                                                justifyContent:"center",
                                                alignItems:"center",
                                            }}
                                        ><item.icon color={item.path === location.pathname ? "black" : "#000000"} width={"50%"} /></div>
                                        <div className="menu-item-content">{item.name}</div>
                                    </Paper>
                                    {/* <div className={"menu-item-inner-container" + (item.path === location.pathname ? " selected" : "")}>
                                    </div> */}
                                </Link>
                                : null
                                )
                            )
                        }

                        <div className="menu-item bottom-item"
                            onClick={handleLogout}
                        >
                            <div className="menu-item-icon"
                                style={{
                                    height:"2rem",
                                    width:"2rem",
                                    borderRadius:"10px",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    background:"#FF6266"            
                                }}
                            ><LogoutIcon color={'white'} width={"50%"} /></div>
                            <div className="menu-item-content"
                                style={{
                                    fontWeight:600,
                                    color:"#1E1E1E",
                                    fontSize:"1rem"
                                }}
                            >Logout</div>
                        </div>
                        <div className="version-info">V 1.1</div>
                    </div>
                </div>

                <div className="dashboard-box">
                    <div className="dashboard-box-header">
                        <div className="dashboard-box-header-title">{menu === 'Home' ? 'Dashboard' : menu}</div>
                        <div className="dashboard-box-header-right"
                            style={{
                                display:"flex",
                            }}
                        >
                            <Fade in={menu === 'Home'}>
                                <Button 
                                    variant="contained" 
                                    style={{
                                        textTransform:'none', 
                                        color:"white",
                                        borderRadius:"12px",
                                        fontWeight:"600",
                                        fontFamily:"montserrat", 
                                        fontSize:"0.9rem",
                                        background:"#EA723C",
                                        marginRight:"2rem",
                                        height:"80%"
                                    }}
                                    onClick={()=>setTour(true)}
                                >Take a Tour</Button>
                            </Fade>
                            <div className="dashboard-box-header-user">
                                <div className="dahsboard-box-header-user-name">Hi, {user.firstname} {user.lastname}</div>
                                <div className="dahsboard-box-header-user-role">{user.role}</div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-box-content">
                        {/* {
                            menuItems.map((item, index)=>(
                                (item?.element && item.name === menu) ? <item.element key={index} /> : null
                            ))
                        } */}
                        <Routes>
                            <Route index path="/" element={<Home onTour={()=>setTour(true)} />}></Route>
                            <Route index path="/how-it-works" element={<HowItWorks />}></Route>
                            <Route path="users" element={
                                user.role === 'superadmin' ? <Users users={users} /> : <ErrorPage errorCode={403} errorText={"UnAuthorized"} />
                            }></Route>
                            <Route path="resumes" element={
                                <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                    <DocumentList key='resumes' documentType={'resumes'} accessUpload={user.access_post} accessDelete={user.access_delete} />
                                </DocumentContainer>
                            }></Route>
                            <Route path="job-descriptions" element={
                                <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                    <DocumentList key={'job-descriptions'} documentType={'job-descriptions'} accessUpload={user.access_post} accessDelete={user.access_delete} />
                                </DocumentContainer>
                            }></Route>
                            <Route path="rank-and-analyze" element={
                                <DocumentContainer documents={documents} setDocuments={setDocuments}>
                                    <RankAndAnalyze key={'rna'} />
                                </DocumentContainer>
                            }></Route>
                            <Route path="faqs" element={
                                <FAQs />
                            }></Route>
                        </Routes>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Dashboard;