import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Tour from 'reactour';
import CrossCircleIcon from '../icons/CrossCircleIcon';
import "../styles/site-tour.css";

const TourContent = ({title, content, onClose}) => {
    return (
        <>
            <div>
                <div
                    style={{
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        maxWidth:"30rem"
                    }}
                >
                    <h4
                        style={{
                            margin:"1rem 0rem",
                            width:"100%"
                        }}
                    >{title}</h4>
                    <IconButton
                        style={{
                            right:"0",
                            position:"absolute",
                            color:"#909090",
                            margin:"0",
                            padding:"0"
                        }}
                        onClick={onClose}
                    ><CrossCircleIcon color="black" height="25" width="25" /></IconButton>        
                </div>
                {content}
            </div>
        </>
    )
}

const SiteTour = ({onEnd}) => {
    const [open, setOpen] = useState(true);
    const [index, setIndex] = useState(-1);
    const [clickList, setClickList] = useState([]);

    const handleClose = () => {
        setOpen(false);
        onEnd();
    }

    const steps = [
        {
            selector : '#resumesPageLink',
            content : <TourContent title={"Resumes"} content={'Upload, Rename or Delete resumes'} onClose={handleClose}/>,
            actionCustom : 'click'
        },
        {
            selector : '#resumesUploadButton',
            content : <TourContent title={"Upload Button"} content={'New resumes can be uploaded here'} onClose={handleClose}/>
        },
        {
            selector : '#jobDescriptionsPageLink',
            content : <TourContent title={"Job Descriptions"} content={'Upload, Rename or Delete job-descriptions'} onClose={handleClose}/>,
            actionCustom : 'click'
        },
        {
            selector : '#job-descriptionsUploadButton',
            content : <TourContent title={"Upload Button"} content={'New job descriptions can be uploaded here'} onClose={handleClose}/>
        },
        {
            selector : '#rankAndAnalyzePageLink',
            content : <TourContent title={"Rank & Ananlyze"} content={'Perform Ranking/Matching of documents'} onClose={handleClose}/>,
            actionCustom : 'click'
        },
        {
            selector : '#radio-buttons-group',
            content : <TourContent title={"Document Type"} content={'First select the type of the document that you want to perform ranking for'} onClose={handleClose}/>,
        },
        {
            selector : '#combo-box-demo',
            content : <TourContent title={"Select Document"} content={'Now select the document'} onClose={handleClose}/>,
        },
        {
            selector : '#rankingTable',
            content : <TourContent title={"Result"} content={'This table shows the matching documents for selected document based on the score and list them in descending order'} onClose={handleClose}/>,
        },
        // {
        //     selector : '#documentPreview',
        //     content : 'view info regarding the document'
        // },
    ]    

    useEffect(()=>{
        setOpen(true);
        handleNextStep();
    }, [])

    const handleNextStep = () => {
        const newIndex = Math.min(index+1, steps.length-1);
        setIndex(newIndex);

        const step = steps[newIndex]
        const element = document.querySelector(steps[newIndex].selector);

        if(step?.actionCustom === 'click'){
            element.click();
            setClickList(prev=>[...prev, element]);
        }else{
            setClickList(prev=>[...prev, prev[prev.length-1]]);
        }
    }
    const handlePrevStep = () => {
        const newIndex = Math.max(index-1, 0);
        setIndex(newIndex);
        clickList[newIndex].click();
    }
    return (
    <>
        <Tour
            steps={steps}
            isOpen={open}
            onRequestClose={handleClose}
            goToStep={index}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
            showCloseButton={false}
            accentColor={"#EA723C"}
            className="site-tour-helper"
            maskSpace="0"
            rounded={"0px"}
            // showButtons={false}
        />

    </>
    )
}

export default SiteTour;