import { Table, TableCell, TableBody, TableRow, TableContainer, TableHead, Paper, Divider} from "@mui/material";
import NotDetected from "./NotDetected";

const resumeRows = {
    'filename' : 'Filename',
    'name' : 'Name',
    'email'    : 'Email',
    'mobile_number' : "Mobile Number",
    'skills' : "Skills",
    'work_experience' : "Work Experience (in Years)",
}

const jdRows = {
    'filename' : 'Filename',
    'job_title' : 'Job Title',
    'company_name'    : 'Company Name',
    'location' : "Location",
    'required_skills' : "Required Skills",
    'required_experience_in_years' : "Required Experience (in Years)",
}

const CustomTabelCell = ({sx, ...params})=>{
    return (
    <TableCell 
        sx={{
            fontFamily:"Montserrat",
            fontSize:"0.85rem",
            background:"white",
            width:"50%",
            border:"none",
            ...sx
        }}
        {...params}
    >
        {params.children}
    </TableCell>
)}

const DocumentPreview = ({document}) => {
    return (
        <>
            <TableContainer component={(props)=><Paper elevation={0} {...props} />} style={{width:"99%", top:"0", fontFamily:"Montserrat", border:"1px solid black", borderRadius:"20px"}}>
                <Table sx={{ width: '100%', height:"100%" }} aria-label="simple table">
                    <TableHead>
                    <TableRow style={{background:"white"}}>
                        <CustomTabelCell sx={{fontWeight:"700", background:"#EA723C1A"}}>Field</CustomTabelCell>
                        <CustomTabelCell sx={{fontWeight:"700", background:"#EA723C1A"}}>Value</CustomTabelCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(document.document_type === 'resumes' ? resumeRows : jdRows).map((key, index) => (
                                <TableRow key={index}>
                                    <CustomTabelCell
                                        sx={{
                                            fontWeight:"600"
                                        }}
                                    >{(document.document_type === 'resumes' ? resumeRows : jdRows)[key]}</CustomTabelCell>
                                    <CustomTabelCell>{
                                        ['n/a', 'none', 'null'].includes(String(document[key]).toLocaleLowerCase()) ? <NotDetected /> : document[key]
                                    }</CustomTabelCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DocumentPreview;