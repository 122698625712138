const PreviewIcon = ({color="#1E1E1E", ...params}) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" {...params} fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4769_1419" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill={color}/>
        </mask>
        <g mask="url(#mask0_4769_1419)">
        <path d="M24.4593 21.5153C24.8198 21.9308 25 22.1385 25 22.5C25 22.8615 24.8198 23.0692 24.4593 23.4847C23.5612 24.5199 21.9381 26 20 26C18.0619 26 16.4388 24.5199 15.5407 23.4847C15.1802 23.0692 15 22.8615 15 22.5C15 22.1385 15.1802 21.9308 15.5407 21.5153C16.4388 20.4801 18.0619 19 20 19C21.9381 19 23.5612 20.4801 24.4593 21.5153Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
        <path d="M24 17.0032V11.8199C24 10.1262 24 9.27928 23.732 8.60291C23.3012 7.51554 22.3902 6.65784 21.2352 6.25228C20.5168 6 19.6173 6 17.8182 6C14.6698 6 13.0956 6 11.8384 6.44148C9.81714 7.15122 8.22281 8.6522 7.46894 10.5551C7 11.7388 7 13.2208 7 16.1848V18.731C7 21.8013 7 23.3364 7.8477 24.4025C8.09058 24.708 8.37862 24.9792 8.70307 25.2078C9.61506 25.8506 10.8502 25.9757 13 26" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 16C7 14.1591 8.49238 12.6667 10.3333 12.6667C10.9991 12.6667 11.784 12.7833 12.4314 12.6099C13.0065 12.4558 13.4558 12.0065 13.6099 11.4314C13.7833 10.784 13.6667 9.99912 13.6667 9.33333C13.6667 7.49238 15.1591 6 17 6" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.9922 22.5H20.0012" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </svg>
    )
}

export default PreviewIcon;