const NotDetected = ({text}) => {
    return (
        <span style={{
            color: 'lightgray'
        }}>
            {text ? text : 'Not Detected'}
        </span>
    )
}

export default NotDetected;