const ResumesIcon = ({color="#1E1E1E", ...props}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.332 14.166L7.4987 14.166" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.332 10.832L10.832 10.832" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.082 11.6654C17.082 14.8081 17.082 16.3794 16.0447 17.3557C15.0074 18.332 13.3378 18.332 9.9987 18.332H9.35476C6.63709 18.332 5.27825 18.332 4.33459 17.6672C4.06421 17.4767 3.82418 17.2508 3.62178 16.9963C2.91536 16.1081 2.91536 14.8292 2.91536 12.2714V10.1502C2.91536 7.6809 2.91536 6.44625 3.30614 5.46016C3.93438 3.87488 5.26298 2.62443 6.94733 2.03316C7.99505 1.66536 9.30687 1.66536 11.9305 1.66536C13.4297 1.66536 14.1794 1.66536 14.7781 1.87553C15.7405 2.2134 16.4997 2.92795 16.8587 3.83382C17.082 4.3973 17.082 5.10281 17.082 6.51385V11.6654Z" stroke={color} strokeLinejoin="round"/>
            <path d="M2.91667 10C2.91667 8.46588 4.16032 7.22222 5.69444 7.22222C6.24926 7.22222 6.90336 7.31944 7.4428 7.1749C7.9221 7.04647 8.29647 6.6721 8.4249 6.1928C8.56944 5.65336 8.47222 4.99926 8.47222 4.44444C8.47222 2.91032 9.71588 1.66667 11.25 1.66667" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ResumesIcon;

