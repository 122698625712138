const EditUserIcon = ({color="#D9D9D9", ...params}) => {
    return (
        <svg width="32" height="32" {...params} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4743_1127" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill={color}/>
        </mask>
        <g mask="url(#mask0_4743_1127)">
        <path d="M14.5 26H10.5909C9.04549 26 7.81631 25.248 6.71266 24.1966C4.45336 22.0441 8.1628 20.324 9.57757 19.4816C12.1281 17.9629 15.2057 17.6118 18 18.4281" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.5 10.5C20.5 12.9853 18.4853 15 16 15C13.5147 15 11.5 12.9853 11.5 10.5C11.5 8.01472 13.5147 6 16 6C18.4853 6 20.5 8.01472 20.5 10.5Z" stroke={color} strokeWidth="1.5"/>
        <path d="M22.4332 17.8485C22.7685 17.4851 22.9362 17.3035 23.1143 17.1975C23.5442 16.9418 24.0736 16.9339 24.5107 17.1765C24.6918 17.2771 24.8646 17.4537 25.2103 17.8067C25.5559 18.1598 25.7287 18.3364 25.8272 18.5214C26.0647 18.9679 26.0569 19.5087 25.8066 19.9478C25.7029 20.1298 25.5251 20.3011 25.1694 20.6437L20.9378 24.7194C20.2638 25.3686 19.9268 25.6932 19.5056 25.8577C19.0845 26.0222 18.6214 26.0101 17.6954 25.9859L17.5694 25.9826C17.2875 25.9752 17.1466 25.9715 17.0646 25.8785C16.9827 25.7855 16.9939 25.6419 17.0162 25.3548L17.0284 25.1988C17.0914 24.3906 17.1228 23.9865 17.2807 23.6232C17.4385 23.2599 17.7107 22.965 18.2552 22.375L22.4332 17.8485Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
        </g>
        </svg>
    )
}

export default EditUserIcon;