const ErrorPage = ({errorCode, errorText}) => {
    return (
        <div
            style={{
                width:'inherit',
                height:'inherit',
                display:"flex",
                alignItems:"center",
                justifyContent:"center"
            }}
        >
            {errorCode} - {errorText}
        </div>
    )
}

export default ErrorPage;