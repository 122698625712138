import socket from "../socket.js";
import { createContext, useContext, useEffect, useState } from "react";

const UploadQueueContext = createContext();
export const useUploadQueue = () => useContext(UploadQueueContext);

export const UploadQueueProvider = ({children}) => {
    const [resumeQueue, setResumeQueue] = useState({
        // file_id : {filename : "filename", status : "SUCCESS"},
        // file_id1 : {filename : "filename", status : "FAILED", error : "type-invalid"},
        // file_id2 : {filename : "filename", status : "RECEIVED"}
    });
    const [jdQueue, setJdQueue] = useState({});

    const updateQueue = (file_id, document_type, update)=>{
        if(document_type === 'resumes'){
            setResumeQueue(prev=>(
                Object.fromEntries(Object.entries(prev).map(([key, item])=>{
                    return key === file_id ? 
                    [key, {
                        ...item,
                        ...update
                    }] : [key, item]
                }))
            ))
        }
        else if(document_type === 'job-descriptions'){
            setJdQueue(prev=>(
                Object.fromEntries(Object.entries(prev).map(([key, item])=>{
                    return key === file_id ? 
                    [key, {
                        ...item,
                        ...update
                    }] : [key, item]
                }))
            ))
        }
        else{
            console.log("Unrecognized document type requested for status update");
        }
    }

    useEffect(()=>{
        socket.on("upload-received", ({file_id, document_type, task_id})=>{
            updateQueue(file_id, document_type, {
                status : "RECEIVED",
                document_type,
                task_id 
            });
        })

        socket.on("upload-success", ({file_id, document_type})=>{
            updateQueue(file_id, document_type, {
                status : "SUCCESS"
            });
        })

        socket.on("upload-failed", ({file_id, document_type, error, attachment=null})=>{
            updateQueue(file_id, document_type, {
                status : "FAILED",
                error : error,
                attachment,
                document_type : document_type
            });
        })
        
        socket.on("abort-success", ({file_id, document_type})=>{
            updateQueue(file_id, document_type, {
                status : "FAILED",
                error : "abort",
                document_type : document_type
            });
        })

        return ()=>{
            socket.off("upload-received");
            socket.off("upload-success");
            socket.off("upload-failed");
            socket.off("abort-success");
        }
    }, []);

    return (
        <UploadQueueContext.Provider value={{resumeQueue, setResumeQueue, jdQueue, setJdQueue, updateQueue}}>
            {children}
        </UploadQueueContext.Provider>
    )
}