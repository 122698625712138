import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import CircularLoading from "./CircularLoading"
import socket from "../socket";

const Authenticator = ({Dashboard, getUserURI="/auth/user", loginURI="/auth/login"})=>{
    const [loading, setLoading] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        // try to featch user info
        setLoading(true);
        fetch(getUserURI)
        .then((response)=>{
          if(response.status === 401){
            // check if was logged in
            if(localStorage.getItem("isLoggedIn")){
              toast.error("Session Expired", {closeOnClick:true});
            }
            setIsLoggedIn(false);
          }
          else if(response.status !== 200){
            setIsLoggedIn(false); // verification failed : redirect to login
          }
          else{
            response.json()
            .then((data)=>{
              setUser(data);
              socket.emit("login", data);
            })
            .catch((error)=>{
              console.log(error);
            })
            setIsLoggedIn(true);
          }

          setLoading(false); // verification success : show page
        })
    }, [getUserURI]);

    useEffect(()=>{
      socket.on("session-expired", ()=>{
        toast.error("Session Expired", {closeOnClick:true});
        navigate(loginURI);
      });

      socket.on("token-request", ()=>{
        fetch("/auth/verify-token",{
          method:"post",
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify({sid:socket.id})
        })
        .then((response)=>{
          if(response.status !== 200){
            toast.error("Session Expired", {closeOnClick:true});
            navigate(loginURI);
          }else{
            socket.emit("request-user")
          }
        })
        .catch((error)=>{
          console.log(error);
        })
      })

      socket.on("unauthorized-request", ()=>{
        toast.error("Access Denied", {closeOnClick:true});
      })

      socket.on("update-user", (user)=>{
        setUser(user);
      })

      return () => {
        socket.off("unauthorized-request");
        socket.off("session-expired");
        socket.off("token-request");
        socket.off("update-user");
      }
    }, [loginURI, navigate]);

    return (
        <div>
            {
                (loading === false) ? isLoggedIn ? (user ? <Dashboard user={user} /> : <CircularLoading />) : <Navigate to={loginURI}/> 
                : 
                <CircularLoading/>
            }
        </div>
    )
}

export default Authenticator;