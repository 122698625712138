import React, { useEffect, useMemo, useState } from "react";
import socket from "../socket";

const DocumentContainer = ({children, documents, setDocuments})=> {
    const [localDocuments, setLocalDocuments] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(false);
    
    useEffect(()=>{
        socket.on("update-documents-self", (documentsData)=>{
            setLocalDocuments(documentsData);
            setDocuments(documentsData);
        });

        return ()=>{
            socket.off("update-documents-self");
        }
    }, [setDocuments]);

    useEffect(()=>{
        if(updateFlag){
            return; // no need to check if updateFlag is already up
        }

        if(localDocuments === null){
            setLocalDocuments(documents);
            return;
        }

        if(documents === null){
            return;
        }

        if(JSON.stringify(Object.keys(localDocuments).sort()) === JSON.stringify(Object.keys(documents).sort())){
            return;
        }

        setUpdateFlag(true);
    }, [updateFlag, localDocuments, documents])

    const updateDocuments = ()=>{
        setLocalDocuments(documents);
        setUpdateFlag(false);
    }

    const childrenWithProps = React.Children.map(children, child => {
        // Clone each child and pass additional props
        return React.cloneElement(child, {
          documents: localDocuments,
          updateFlag: updateFlag,
          updateDocuments : updateDocuments
        });
      });

    return (
        <>
            {childrenWithProps}
        </>
    )
}

export default DocumentContainer;